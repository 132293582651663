import React from 'react'

const Verify_Login = () => {
    return (
        <div className="wrapper">
            <div className="section-authentication-cover">
                <div className>
                    <div className="row g-0">
                        <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
                            <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0">
                                <div className="card-body">
                                    <img src="assets/images/login-images/register-cover.png" className="img-fluid auth-img-cover-login" width={550} alt />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center">
                            <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0">
                                <div className="card-body p-sm-5">
                                    <div className>
                                        <div className="mb-3 text-center">
                                            <img src="assets/images/logo-light.png" className="w-50" alt />
                                        </div>
                                        <div className="text-center mb-4">
                                            <h5 className>Trigwell cosmetics </h5>
                                            <p className="mb-0 font-22">Enter your verification code</p>
                                            <p>We’ve sent an invite to your registered email.</p>
                                        </div>
                                        <div className="form-body">
                                            <form className="row g-3" id="jQueryValidationForm" method="post3" action="/">
                                                <div className="col-12">
                                                    <div className="verification-code--inputs">
                                                        <input type="text" maxLength={1} />
                                                        <input type="text" maxLength={1} />
                                                        <input type="text" maxLength={1} />
                                                        <input type="text" maxLength={1} />
                                                        <input type="text" maxLength={1} />
                                                        <input type="text" maxLength={1} />
                                                    </div>
                                                    <div className="timer-box mb-2 text-center">Recent code in: <b> <span id="time-chnge">60</span> second</b></div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="d-grid">
                                                        <button type="submit" className="btn btn-primary">Verify</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </div>
            </div>
        </div>

    )
}

export default Verify_Login