import React from 'react'
import { Editor } from 'primereact/editor';
import Button from '../components/Button';

const Static_Content = () => {

    const toolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
          {label: 'Italic', style: 'ITALIC'},
          {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_DROPDOWN: [
          {label: 'Normal', style: 'unstyled'},
          {label: 'Heading Large', style: 'header-one'},
          {label: 'Heading Medium', style: 'header-two'},
          {label: 'Heading Small', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
      };
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Static content</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Static content</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-primary" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" data-bs-toggle="tab" href="#privacy_tab" role="tab" aria-selected="false">
                                    <div className="d-flex align-items-center">
                                        <div className="tab-icon"><i className="bx bx-file font-20 me-1" />
                                        </div>
                                        <div className="tab-title">Privacy Policy</div>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" data-bs-toggle="tab" href="#standard_tab" role="tab" aria-selected="false">
                                    <div className="d-flex align-items-center">
                                        <div className="tab-icon"><i className="bx bx-home font-20 me-1" />
                                        </div>
                                        <div className="tab-title">Terms &amp; Conditions </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="privacy_tab" role="tabpanel">
                                <div>
                                    <Editor value={''} onTextChange={(e) => console.log(e.htmlValue)} style={{ height: '320px' }} />
                                    <div className="editor_btns mt-3">
                                        <ul className="d-flex align-items-center justify-content-end gap-2">
                                            <li>
                                                <Button text="Cancel" className="btn btn-primary" />
                                            </li>
                                            <li>
                                                <Button text="Save" className="btn btn-primary" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="standard_tab" role="tabpanel">
                                <div>
                                    <Editor value={''} onTextChange={(e) => console.log(e.htmlValue)} style={{ height: '320px' }} />
                                    <div className="editor_btns mt-3">
                                        <ul className="d-flex align-items-center justify-content-end gap-2">
                                            <li>
                                                <Button text="Cancel" className="btn btn-primary" />
                                            </li>
                                            <li>
                                                <Button text="Save" className="btn btn-primary" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Static_Content