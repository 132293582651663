import React from 'react'
import Button from '../components/Button'

const Setting = () => {
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Settings</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="javascript:;"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="container">
                    <div className="main-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-column align-items-center text-center">
                                            <img src="assets/images/avatars/avatar-1.png" alt="Admin" className="rounded-circle p-1 bg-primary" width={110} />
                                            <div className="mt-3">
                                                <h4>John Doe</h4>
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <div className="upload_profile position-relative">
                                                        <input type="file" className="form-control position-absolute opacity-0" />
                                                        <Button className="btn btn-primary" text="Upload Photo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <form id="jQueryValidationForm" method="post3" action>
                                            <div className="row mb-3 align-items-center">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">Current Password</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <input type="password" className="form-control" name="password" id="setting1" />
                                                </div>
                                            </div>
                                            <div className="row mb-3 align-items-center">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">New Password</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <input type="password" className="form-control" name="new_pass" id="setting2" />
                                                </div>
                                            </div>
                                            <div className="row mb-3 align-items-center">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0">Retype Password</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <input type="password" className="form-control" name="confirm_password" id="setting3" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-9 text-secondary">
                                                    <Button className="btn btn-primary px-4" text="Save Changes" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Setting