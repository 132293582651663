import React, { useEffect, useState } from "react";
import ReactDomServer from "react-dom/server";
import Button from "../components/Button";
import Modal_Actions from "../components/Modal_Actions";
import ReactModal from "../components/ReactModal";
import Table from "../components/Table";
import AddVideos from "../components/AddVideos";
import { MessageShow, Validator } from "../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCategry,
  AddCourse,
  DeleteCourse,
  EditCourse,
  GetDeleteCate,
  DeleteCourseOverview,
  DeleteCourseCategories,
  DeleteCourseProducts,
  DeleteCourseLesson,
} from "../reducer/Auth";
import Modal from "../components/Modal";
import AddCate from "../components/AddCate";
import Select from "react-select";
import ImageUploader from "../components/ImageUploader";

const Content_managment = () => {
  const [open, setOpen] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);

  const GetButtons = (element, index) => {
    return ReactDomServer.renderToString(
      <ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
        <li>
          <a
            href="javaScript:;"
            data-data={JSON.stringify(element)}
            data-name="edit"
          >
            <i className="bx bx-edit"></i>
          </a>
        </li>{" "}
        <li>
          <a
            href="javaScript:;"
            data-data={JSON.stringify(element)}
            data-name="delete"
          >
            <i className="bx bx-trash"></i>
          </a>
        </li>
      </ul>
    );
  };
  const { Course, ProductList } = useSelector((State) => State.Auth);

  const [LIST, setLIST] = useState({});

  function breakString(inputString, spacesPerLine) {
    // Use regular expression to find every 10 spaces
    const regex = new RegExp(`(([^ ]* ){${spacesPerLine}}[^ ]*)`, "g");

    // Replace matches with the matched substring followed by a newline
    const resultString = inputString.replace(regex, "$1<br>");

    return resultString;
  }

  const [openCateModal, setOpenCateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCourseModal, setdeleteCourseModal] = useState(false);
  // Overview images
  const [images, setImages] = useState([]);
  const [overViewFile, setOverViewFile] = useState([]);

  // const handleImageChange = (e) => {
  //     e.preventDefault();
  //     const files = Array.from(e.target.files)
  //     console.log("multiple",e.target.files);

  //     const imagePreviews = files.map(file => URL.createObjectURL(file));
  //     overViewImages.push(URL.createObjectURL(files))
  //     // setOverViewImages(imagePreviews)
  //     // setImages(imagePreviews);
  //     console.log("overViewImages",overViewImages);
  //   };
const [editOverview , setEditOverview] = useState([])
  const deleteOverview = async (index) => {
    dispatch(
      DeleteCourseOverview(`${craeteNew._id}/${craeteNew.overviews[index]._id}`)
    );
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const deleteCourseLesson = () => {
    // dispatch(DeleteCourseLesson(`${craeteNew._id}/${craeteNew.overviews[index]._id}`))
  };
  const deleteCourseCategories = () => {
    // dispatch(DeleteCourseCategories(`${craeteNew._id}/${craeteNew.overviews[index]._id}`))
  };
  const deleteCourseProducts = () => {
    // dispatch(DeleteCourseProducts(`${craeteNew._id}/${craeteNew.overviews[index]._id}`))
  };

  useEffect(() => {
    if (Course?.Course) {

      let table_data = {
        data: [],
        columns: [
          { title: "Cover Pic" },
          { title: "Course Name" },
          { title: "Description" },
          { title: "Overview details" },
          { title: "Overview Images" },
          { title: "Videos" },
          { title: "Products" },
          { title: "Cetgories" },
          { title: "Actions" },
        ],
      };
      let Data = [...Course?.Course];
      // let Data = [...Course?.Course, ...Course?.newCourse]

      Data.forEach((element) => {
        const {
          thumbnail,
          title,
          description,
          over_view,
          products,
          videos,
          filter,
          overviews,
        } = element;
        table_data.data.push([
          `<img src="${thumbnail}" alt class="product-C-img" />`,
          title || "- - -",
          breakString(description, 6) || "- - -",
          breakString(over_view, 6) || "- - -",
          overviews.length || "- - -",
          videos?.length || "- - -",
          products?.map((item) => item?.value).join("</br>") || "- - -",
          filter?.map((item) => item?.title).join("</br>") || "- - -",
          GetButtons(element),
        ]);
      });
      setLIST(table_data);
    }
  }, [Course]);

  const [craeteNew, setCreateNew] = useState({
    currency: "eur",
    subscription: "month",
    isNew: true,
    title: "",
    thumbnail: "",
    trailer: "",
    description: "",
    Overview: "",
    "Overview Images": [],
    // "price": '',
    filter: [],
    videos: [],
    products: [],
  });
  const [allProducts, setAllProducts] = useState([]);
  const [newCate, setNewCate] = useState([]);

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const loadFile = (event, index, filed) => {
    var reader = new FileReader();
    reader.onload = async function () {
      let isMediaAdd = event.target.files[0];
      // ShowCurrentImage.src = reader.result;
      let file = {
        uri: reader.result,
        name: isMediaAdd.name,
        type: isMediaAdd.type, // it may be necessary in Android.
      };

      if (!filed) {
        let VIDEO = craeteNew?.videos;
        let DD = await getVideoDuration(isMediaAdd);
        VIDEO[index] = { ...VIDEO[index], url: isMediaAdd, file, duration: DD };
        setCreateNew((item) => ({ ...item, videos: VIDEO }));
      } else {
        setCreateNew((item) => ({ ...item, [filed]: isMediaAdd }));
      }
      // console.log(reader.result)
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleVideoThumbnail = (event, index) => {
    var reader = new FileReader();
    reader.onload = async function () {
      let isMediaAdd = event.target.files[0];
      let file = {
        uri: reader.result,
        name: isMediaAdd.name,
        type: isMediaAdd.type, // it may be necessary in Android.
      };

      let VIDEO = craeteNew?.videos;
      VIDEO[index] = { ...VIDEO[index], thumbnail: isMediaAdd };
      setCreateNew((item) => ({ ...item, videos: VIDEO }));
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const CategoriesImahe = (event, index, filed) => {
    var reader = new FileReader();
    reader.onload = async function () {
      let isMediaAdd = event.target.files[0];
      // ShowCurrentImage.src = reader.result;
      let file = {
        uri: reader.result,
        name: isMediaAdd.name,
        type: isMediaAdd.type, // it may be necessary in Android.
      };
      let VIDEO = [...newCate];
      VIDEO[index] = { ...VIDEO[index], file: isMediaAdd };
      setNewCate(VIDEO);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const handleSend = () => {
    let valid = Validator(craeteNew);
    if (valid) {
      let formdata = new FormData();
      Object.keys(craeteNew).forEach((items) => {
        if (items === "videos") {
          craeteNew.videos.forEach((items, index) => {
            if (items.url) {
              // console.log("console.log");
              formdata.append(`url_${index}`, items.url);
            }
            if (items?.thumbnail) {
              formdata.append(`thumbnail_${index}`, items.thumbnail);
            }
          });
        } else if (items === "filter") {
          formdata.append(`${items}`, JSON.stringify(craeteNew[items]));
        } else if (items === "Overview Images") {
          craeteNew["Overview Images"].forEach((item, index) => {
            formdata.append(`overview_${index}`, item);
          });
        } else if (items === "products") {
          formdata.append(`${items}`, JSON.stringify(craeteNew[items]));
        } else if (items === "thumbnail") {
          formdata.append(`thumbnail`, craeteNew[items]);
        } else if (items === "trailer") {
          formdata.append(`trailer`, craeteNew[items]);
        } else if (items === "Overview") {
          formdata.append(`over_view`, craeteNew[items]);
        } else {
          formdata.append(`${items}`, craeteNew[items]);
        }
      });
      let handleVId = craeteNew.videos?.map((items) => ({
        title: items?.title,
        description: items?.description,
        duration: items?.duration,
      }));
      let overviewImage = craeteNew["Overview Images"]?.map((items) => {
        return { media: items, status: true };
      });
      formdata.append(`videos`, JSON.stringify(handleVId));
      formdata.append(`overviews`, JSON.stringify(overviewImage));
      // for (var pair of formdata.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }
      dispatch(
        AddCourse({ body: formdata, callback: () => setLoading(false) })
      );
      setOpen(false)
      setLoading(true)
    }
  };

  const handleEid = () => {


      let formdata = new FormData();
      formdata.append("_id", craeteNew._id);
      formdata.append("title", craeteNew.title);
      formdata.append("description", craeteNew.description);
      formdata.append("over_view", craeteNew.Overview);
      formdata.append("thumbnail", (typeof craeteNew.thumbnail) === "string" ? "":craeteNew.thumbnail);
      formdata.append("trailer", (typeof craeteNew.trailer) === "string" ? "" : craeteNew.trailer);

      formdata.append("products", JSON.stringify(craeteNew.products));
    //   formdata.append("filter", JSON.stringify(craeteNew.filter));

      Object.keys(craeteNew).forEach((items) => {
        if (items === "videos") {
          craeteNew.videos.forEach((items, index) => {
            if (items.url) {
              if (items?.file) {
                formdata.append(`url_${index}`, items.url);
              }
            }
            if (items?.thumbnail) {
              if (items?.thumbnail?.type) {
                formdata.append(`thumbnail_${index}`, items.thumbnail);
              }
            }
          });
        } else if (items === "Overview Images") {
            editOverview.forEach((item, index) => {
            formdata.append(`overview_${index}`, item);
          });
        }
      });
      // console.log("craeteNew.products",craeteNew.products);
      // Object.keys(craeteNew).forEach((items) => {
      //     if (items === 'videos') {
      //         craeteNew.videos.forEach((items, index) => {
      //             if (items.url) {
      //                 if (items?.file) {
      //                     formdata.append(`${index}`, items.url)
      //                 }
      //             }
      //             if (items?.thumbnail) {
      //                 if (items?.thumbnail?.type) {
      //                     formdata.append(`thumbnail_${index}`, items.thumbnail)
      //                 }
      //             }
      //         })
      //     } else if (items === 'filter') {
      //         formdata.append(`${items}`, JSON.stringify(craeteNew[items]))
      //     } else if (items === 'products') {
      //         formdata.append(`${items}`, JSON.stringify(craeteNew[items]))
      //     } else if (items === 'thumbnail') {
      //         formdata.append(`thumbnail`, craeteNew[items])
      //     } else if (items === 'trailer') {
      //         formdata.append(`trailer`, craeteNew[items])
      //     }
      //     else if (items === '_id') {

      //     } else {
      //         formdata.append(`${items}`, craeteNew[items])
      //     }
      //     // formdata.append(`file[index]`, isMediaAdd, isMediaAdd.name)
      // })

      let handleVId = craeteNew.videos?.map((items) => ({
        title: items?.title,
        description: items?.description,
        duration: items?.duration,
        thumbnail: items?.thumbnail,
        url: items?.url,
      }));
      let overviewImage = editOverview?.map((items) => {
        return { media: items, status: true };
      });
      formdata.append(`videos`, JSON.stringify(handleVId));
      formdata.append(`overviews`, JSON.stringify(overviewImage));
      // console.log("overviewImage", editOverview);



      // for (var pair of formdata.entries()) {
      //     console.log("edit handel",pair[0]+ ', ' + pair[1]);
      // }
      dispatch(
        EditCourse({ body: formdata, callback: () => setLoading(false) })
      );
      setOpenEditForm(false)
      setLoading(true)
      setEditOverview([])
      // setCreateNew({
      //   currency: "eur",
      //   subscription: "month",
      //   isNew: true,
      //   title: "",
      //   thumbnail: "",
      //   trailer: "",
      //   description: "",
      //   Overview: "",
      //   "Overview Images": [],
      //   // "price": '',
      //   filter: [],
      //   videos: [],
      //   products: [],
      // });
      // setImages([]);
  
    // }
  };

  const handleSendCate = () => {
    let valid = Validator(newCate);
    if (valid) {
      let formdata = new FormData();
      let allTure = [];
      newCate.forEach((items, index) => {
        if (items?.title) {
          allTure.push(true);
          formdata.append(`title`, items?.title);
        } else {
          MessageShow("error", `Category ${index + 1} title is required`);
          allTure.push(false);
        }
        if (items?.file) {
          allTure.push(true);
          formdata.append(`icon_${index}`, items?.file);
        } else {
          MessageShow("error", `Category ${index + 1} icon is required`);
          allTure.push(false);
        }
      });
      if (allTure?.filter((i) => i)?.length) {
        dispatch(
          AddCategry({
            body: formdata,
            callback: () => {
              setNewCate([]);
            },
          })
        );
      }
    }
  };

  const GetDeletButtons = (element) => {
    return ReactDomServer.renderToString(
      <ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
        <li>
          <a
            href="javaScript:;"
            data-data={JSON.stringify(element)}
            data-name="delete-Cate"
          >
            <i className="bx bx-trash"></i>
          </a>
        </li>
      </ul>
    );
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    // Iterate through selected files
    files.forEach((file, index) => {
      // craeteNew..push(e.target.files[index])
      craeteNew["Overview Images"].push(e.target.files[index]);
      // Create a URL for each selected image
      setEditOverview((item)=>[...item, e.target.files[index]])
      const reader = new FileReader();
      reader.onload = (e) => {
        setImages((prevImages) => [...prevImages, e.target.result]);
      };
      reader.readAsDataURL(file);
    });

    // console.log("images", images);
  };
  const handleFileEdit = (e) => {
    const files = Array.from(e.target.files);
    // Iterate through selected files
    files.forEach((file, index) => {
      // craeteNew..push(e.target.files[index])
      craeteNew["Overview Images"].push(e.target.files[index]);
      // Create a URL for each selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        setImages((prevImages) => [...prevImages, e.target.result]);
      };
      reader.readAsDataURL(file);
    });
 
    // console.log("images", images);
  };

  // Function to handle image removal
  const removeImage = (index) => {
    const imageFiles = [...craeteNew["Overview Images"]];
    imageFiles.splice(index, 1);
    // console.log("imageFiles",imageFiles);
    setCreateNew((prevImages) => {
      return {
        ...prevImages,
        "Overview Images": imageFiles,
      };
    });
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (ProductList) {
      let LIST = [];
      ProductList?.forEach((PRODUCT) => {
        LIST.push({
          value: `${PRODUCT.title} ,#ID:${PRODUCT?.product_id}`,
          label: `${PRODUCT.title} ,#ID:${PRODUCT?.product_id}`,
        });
      });
      setOptions(LIST);
    }
  }, [ProductList]);

  const _selectProduct = (values, i) => {
    setCreateNew((item) => ({ ...item, products: values }));
  };
//   console.log("ALL_PRODUCTS==>", allProducts);
  return (
    <>
      {/* <div style={{background:"blue",opacity:0.2,height:"100vh",position:"absolute",zIndex:9999,width:"100vw",right:0}}>

       </div> */}
      <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Content Management</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="index.html">
                      <i className="bx bx-home-alt" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Content Management
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              <div className="btn-group">
                {Loading ? (
                  <Button
                    text="Creating..."
                    className="d-flex align-items-center btn btn-white font-18"
                    icon={
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    }
                  />
                ) : (
                  <Button
                    text="Create Course"
                    onClick={() => {
                      setOpen(true);
                    }}
                    className="d-flex align-items-center btn btn-primary font-18"
                    icon={<i className="bx bx-plus ms-1" />}
                  />
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="card">
            <div className="card-body">
              <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                <div className="ms-auto">
                  <div className="btn-group mr-3">
                    <Button
                      text="Categories"
                      onClick={() => {
                        setOpenCateModal(true);
                      }}
                      className="d-flex align-items-center btn btn-primary font-18 ml-3"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-content py-3">
                <div
                  className="tab-pane fade show active"
                  id="primaryhome"
                  role="tabpanel"
                >
                  {LIST?.data ? (
                    <div className="table-responsive">
                      <Table
                        {...LIST}
                        callback={(e, type) => {
                          // console.log(e)
                          if (type == "edit") {
                            setOpenEditForm(true);
                            setAllProducts(e.products);
                            setCreateNew({
                              ...e,
                              Overview: e.over_view,
                              "Overview Images": e.overviews,
                              edit: true,
                            });
                            // console.log(
                            //   "edit log",
                            //   e.overviews.map((item) => item.media)
                            // );
                            setImages(e.overviews.map((item) => item.media));
                          } else {
                            setdeleteCourseModal(e);
                          }
                        }}
                      />
                      <Modal
                        data={LIST.data}
                        field={LIST.columns}
                        SubmitAPI="/api-url"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <ReactModal
            open={open}
            onClose={() => {
              setOpen(false);
              setCreateNew({
                currency: "eur",
                subscription: "month",
                isNew: true,
                title: "",
                thumbnail: "",
                trailer: "",
                description: "",
                Overview: "",
                "Overview Images": [],
                // "price": '',
                filter: [],
                videos: [],
                products: [],
              });
              setImages([]);
            }}
            title={craeteNew?.edit ? "Edit Course" : "Add Course"}
          >
            <div class="modal-body">
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Course Name
                </label>
                <input
                  type={"text"}
                  name={"title"}
                  onChange={(e) =>
                    setCreateNew((item) => ({ ...item, title: e.target.value }))
                  }
                  className="form-control"
                  value={craeteNew?.title}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Thumbnail
                </label>
                {craeteNew?.edit ? (
                  <img
                    accept=".png, .jpg, .jpeg"
                    src={craeteNew?.thumbnail}
                    alt
                    class="product-C-img"
                  />
                ) : null}
                <input
                  accept="image/png, image/gif, image/jpeg"
                  type={"file"}
                  onChange={(e) => loadFile(e, false, "thumbnail")}
                  name={"thumbnail"}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Trailer
                </label>
                <input
                  type={"file"}
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => loadFile(e, false, "trailer")}
                  name={"trailer"}
                  className="form-control"
                />
              </div>

              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Description
                </label>
                <textarea
                  type={"text"}
                  name={"description"}
                  onChange={(e) =>
                    setCreateNew((item) => ({
                      ...item,
                      description: e.target.value,
                    }))
                  }
                  className="form-control"
                  value={craeteNew?.description}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Overview
                </label>
                <input
                  type={"text"}
                  name={"Overview"}
                  onChange={(e) =>
                    setCreateNew((item) => ({
                      ...item,
                      Overview: e.target.value,
                    }))
                  }
                  className="form-control"
                  value={craeteNew?.Overview}
                />
              </div>
              <div className="col-md-12 mt-3">
                <div
                  style={{
                    padding: 3,
                    overflowX: "scroll",
                    display: "flex",
                    gap: 8,
                  }}
                >
                  {images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={image}
                        alt={`Preview ${index}`}
                        style={{
                          width: "100px",
                          height: "80px",
                          background: "white",
                          objectFit: "contain",
                          borderRadius: 10,
                          margin: 0,
                        }}
                      />
                      <button
                        onClick={() =>
                          craeteNew.edit
                            ? deleteOverview(index)
                            : removeImage(index)
                        }
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "5px",
                          background: "white",
                          color: "black",
                          border: "none",
                          padding: "5px",
                          cursor: "pointer",
                          borderRadius: 50,
                          width: 22,
                          height: 22,
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 2,
                        }}
                      >
                        &#x2715;
                      </button>
                    </div>
                  ))}
                </div>

                <label htmlFor="input1" className="form-label">
                  Overview Images
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={craeteNew?.edit ? handleFileChange : handleFileEdit}
                  multiple
                  accept="image/*"
                />
              </div>

              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Categories
                </label>
                <Select
                  isMulti
                  options={Course?.Filters?.map((item) => ({
                    value: item,
                    label: item.title,
                  }))}
                  value={
                    craeteNew?.filter?.map((item) => ({
                      value: item,
                      label: item.title,
                    })) || []
                  }
                  onChange={(array) => {
                    setCreateNew((item) => ({
                      ...item,
                      filter: array?.map((_item) => _item?.value),
                    }));
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Products
                </label>
                <Select
                  isMulti
                  options={options}
                  value={craeteNew.products}
                  onChange={(array) => {
                    setCreateNew((item) => ({ ...item, products: array }));
                  }}
                  // options={colourOptions}
                />
              </div>
              {/* ///// */}
              {craeteNew?.videos?.map((item, index) => (
                <AddVideos
                  isEdit={craeteNew?.edit}
                  item={item}
                  craeteNew={craeteNew}
                  index={index}
                  setCreateNew={setCreateNew}
                  onChange={loadFile}
                  handleVideoThumbnail={handleVideoThumbnail}
                />
              ))}

              {/* ///// */}
              <div className="col-md-12 mt-3">
                <Button
                  className="btn btn-primary"
                  onClick={() =>
                    setCreateNew((item) => ({
                      ...item,
                      videos: item?.videos ? [...item?.videos, {}] : [{}],
                    }))
                  }
                  text="+ Add Lesson"
                />
              </div>
            </div>
            <div class="modal-footer">
              <Button
                className="btn btn-primary"
                onClick={craeteNew?.edit ? handleEid : handleSend}
                text="Save Changes"
              />
            </div>
          </ReactModal>
          {/* edit form data */}
          <ReactModal
            open={openEditForm}
            onClose={() => {
              setOpenEditForm(false);
              setCreateNew({
                currency: "eur",
                subscription: "month",
                isNew: true,
                title: "",
                thumbnail: "",
                trailer: "",
                description: "",
                Overview: "",
                "Overview Images": [],
                // "price": '',
                filter: [],
                videos: [],
                products: [],
              });
              setImages([]);
            }}
            title={"Edit Course"}
          >
            <div class="modal-body">
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Course Name
                </label>
                <input
                  type={"text"}
                  name={"title"}
                  onChange={(e) =>
                    setCreateNew((item) => ({ ...item, title: e.target.value }))
                  }
                  className="form-control"
                  value={craeteNew?.title}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Thumbnail
                </label>
                {/* {craeteNew?.edit ? <img accept=".png, .jpg, .jpeg" src={craeteNew?.thumbnail} alt class="product-C-img" /> : null} */}
                <input
                  accept="image/png, image/gif, image/jpeg"
                  type={"file"}
                  onChange={(e) => loadFile(e, false, "thumbnail")}
                  name={"thumbnail"}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Trailer
                </label>
                <input
                  type={"file"}
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => loadFile(e, false, "trailer")}
                  name={"trailer"}
                  className="form-control"
                />
              </div>

              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Description
                </label>
                <textarea
                  type={"text"}
                  name={"description"}
                  onChange={(e) =>
                    setCreateNew((item) => ({
                      ...item,
                      description: e.target.value,
                    }))
                  }
                  className="form-control"
                  value={craeteNew?.description}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Overview
                </label>
                <input
                  type={"text"}
                  name={"Overview"}
                  onChange={(e) =>
                    setCreateNew((item) => ({
                      ...item,
                      Overview: e.target.value,
                    }))
                  }
                  className="form-control"
                  value={craeteNew?.Overview}
                />
              </div>
              <div className="col-md-12 mt-3">
                <div
                  style={{
                    padding: 3,
                    overflowX: "scroll",
                    display: "flex",
                    gap: 8,
                  }}
                >
                  {images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={image}
                        alt={`Preview ${index}`}
                        style={{
                          width: "100px",
                          height: "80px",
                          background: "white",
                          objectFit: "contain",
                          borderRadius: 10,
                          margin: 0,
                        }}
                      />
                      <button
                        onClick={() => deleteOverview(index)}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "5px",
                          background: "white",
                          color: "black",
                          border: "none",
                          padding: "5px",
                          cursor: "pointer",
                          borderRadius: 50,
                          width: 22,
                          height: 22,
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 2,
                        }}
                      >
                        &#x2715;
                      </button>
                    </div>
                  ))}
                </div>
                <label htmlFor="input1" className="form-label">
                  Overview Images
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={craeteNew?.edit ? handleFileChange : handleFileEdit}
                  multiple
                  accept="image/*"
                />
              </div>

              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Categories
                </label>
                <Select
                  isMulti
                  isDisabled
                  options={Course?.Filters?.map((item) => ({
                    value: item,
                    label: item.title,
                  }))}
                  value={
                    craeteNew?.filter?.map((item) => ({
                      value: item,
                      label: item.title,
                    })) || []
                  }
                  onChange={(array) => {
                    // console.log("Categories", array);
                    setCreateNew((item) => ({
                      ...item,
                      filter: array?.map((_item) => _item?.value),
                    }));
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label htmlFor="input1" className="form-label">
                  Products
                </label>
                <Select
                  isMulti
                  options={options}
                  value={craeteNew.products}
                  // onMenuClose={(e)=>console.log("e",e)}

                  onChange={_selectProduct}
                  // options={colourOptions}
                />
              </div>
              {/* ///// */}
              {craeteNew?.videos?.map((item, index) => (
                <AddVideos
                  isEdit={craeteNew?.edit}
                  item={item}
                  craeteNew={craeteNew}
                  index={index}
                  setCreateNew={setCreateNew}
                  onChange={loadFile}
                  handleVideoThumbnail={handleVideoThumbnail}
                />
              ))}

              {/* ///// */}
              <div className="col-md-12 mt-3">
                <Button
                  className="btn btn-primary"
                  onClick={() =>
                    setCreateNew((item) => ({
                      ...item,
                      videos: item?.videos ? [...item?.videos, {}] : [{}],
                    }))
                  }
                  text="+ Add Lesson"
                />
              </div>
            </div>
            <div class="modal-footer">
              <Button
                className="btn btn-primary"
                onClick={handleEid}
                text="Save Changes"
              />
            </div>
          </ReactModal>
          <ReactModal
            open={openCateModal}
            onClose={() => setOpenCateModal(false)}
            title="Cagtegory List"
          >
            <div class="modal-body">
              <div className="col-md-12 mt-3">
                <div className="tab-content py-3">
                  <div
                    className="tab-pane fade show active"
                    id="primaryhome2"
                    role="tabpanel"
                  >
                    <div className="table-responsive">
                      <Table
                        {...{
                          data: Course?.Filters?.map((item) => [
                            `<img src="${item.icon}" alt class="product-img-2" />`,
                            item.title,
                            GetDeletButtons(item),
                          ]),
                          columns: [
                            { title: "Icon" },
                            { title: "Title" },
                            { title: "Action" },
                          ],
                          T_name: "IID3",
                        }}
                        callback={(e) => {
                          setDeleteModal(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* ///// */}
              {newCate?.map((item, index) => (
                <AddCate
                  item={item}
                  craeteNew={[...newCate]}
                  index={index}
                  setCreateNew={setNewCate}
                  handleIcon={CategoriesImahe}
                />
              ))}

              {/* ///// */}
              <div className="col-md-12 mt-3">
                <Button
                  className="btn btn-primary"
                  onClick={() => setNewCate((item) => [...item, {}])}
                  text="+ Add Category"
                />
              </div>
            </div>
            <div class="modal-footer">
              <Button
                className="btn btn-primary"
                onClick={handleSendCate}
                text="Save Changes"
              />
            </div>
          </ReactModal>
          <ReactModal
            open={deleteModal}
            title="Confirmation Alert!"
            onClose={() => setDeleteModal(false)}
          >
            <div className="modal-body">
              <div className="create_noti_form">
                <h5>Do you want to delete {deleteModal.title} category?</h5>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setDeleteModal(false)}
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  dispatch(GetDeleteCate(deleteModal?._id));
                  setDeleteModal(false);
                }}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </ReactModal>
          <ReactModal
            open={deleteCourseModal}
            title="Confirmation Alert!"
            onClose={() => setdeleteCourseModal(false)}
          >
            <div className="modal-body">
              <div className="create_noti_form">
                <h5>Do you want to delete {deleteCourseModal.title} course?</h5>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setdeleteCourseModal(false)}
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  dispatch(DeleteCourse(deleteCourseModal?._id));
                  setdeleteCourseModal(false);
                }}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </ReactModal>
          {Loading ? (
            <ReactModal open={true} title="Please wait for your course uploading in process...">
              <div className="modal-body">
                <div
                  className="create_noti_form"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div id="loaderSection"></div>
                </div>
              </div>
            </ReactModal>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Content_managment;

// {
//     "title": "Wedding Look",
//     "currency": "usd",
//     "description": "New MakeUp",
//     "Overview": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tortor mi, eleifend in lacus ac, accumsan lacinia enim. Sed non tellus dictum",
//     "price": 1500,
//     "thumbnail": "https://trigwellcosmetics.com/cdn/shop/files/TTBundles21.png?v=1690460376&width=1080",
//     "subscription": "month",
//     "filter": [
//         {
//             "title": "Eyelashes",
//             "icon": "https://trigwellcosmetics.com/cdn/shop/files/TTBundles21.png?v=1690460376&width=1080"
//         }
//     ],
//     "trailer": "https://assets.mixkit.co/videos/preview/mixkit-happy-bride-looks-in-the-mirror-40589-large.mp4",
//     "isNew": true,
//     "videos": [
//         {
//             "title": "Face look",
//             "description": "New MakeUp",
//             "thumbnail": "https://assets.mixkit.co/videos/preview/mixkit-makeup-artist-working-in-her-beauty-salon-40540-large.mp4",
//             "url": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
//             "duration": "42000"
//         },
//         {
//             "title": "Eye Shades",
//             "description": "New MakeUp",
//             "thumbnail": "https://assets.mixkit.co/videos/preview/mixkit-look-of-a-girl-while-putting-makeup-on-her-eyelids-40552-large.mp4",
//             "url": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
//             "duration": "42000"
//         }
//     ]
// }
