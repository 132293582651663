import React, { useEffect, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import Button from '../components/Button';
import ReactModal from '../components/ReactModal';
import Table from '../components/Table';
import { MessageShow, Validator } from '../Utils/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { AddMonthlyLimit,DeleteMonthlyLimit, EditMonthlyLimit } from '../reducer/Auth';
import Modal from '../components/Modal';
import moment from 'moment';

const MonthlySubscriptionLimit= () => {

    const { MonthlyLimit } = useSelector(State => State.Auth)

    const [open, setOpen] = useState(false)
    const [editOpen,setEditOpen] = useState(false)
    const [LIST, setLIST] = useState({})
    const [deleteMonthlyLimit, setDeleteMonthlyLimit] = useState(false)
    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(false)

    const [createMonthlyLimit, setCreateMonthlyLimit] = useState({
        "date": "",
        "limit": "",
        "status": "true"
    })
    const [editMonthlyLimit,setEditMonthlyLimit ] = useState({
        "date": "",
        "limit": "",
        "status": "true",
    })

    const [minDate,setMinDate] = useState("2024-05")

    useEffect(() => {
        if (MonthlyLimit.length) {
            // Calculate the minimum date
            // const minDate = moment.min(momentDates);
            // const minDateFormatted = minDate.isValid() ? minDate.format("YYYY-MM") : '';
            // setMinDate(minDateFormatted)
            let table_data = {
                data: [],
                columns: [
                    { title: 'Date' },
                    { title: 'Limit' },
                    { title: 'Actions' }
                ],
            }
            MonthlyLimit?.forEach(element => {
                const {
                    date,
                    limit,
                } = element
                table_data.data.push([
                    moment(date).format("MMMM YYYY") || '- - -',
                    limit || '- - -',
                    GetButtons(element)
                ])
            });
            setLIST(table_data)
        }
    }, [MonthlyLimit])
    
    const handleSend = async() => {
        let valid = Validator(createMonthlyLimit)
        if (valid) {
            const momentDates = MonthlyLimit?.map(item => moment(item.date).format("YYYY-MM"));
            if(momentDates.includes(createMonthlyLimit?.date)) return  MessageShow("error", "This Month Is Already Exist");
            const data = {
                date: createMonthlyLimit?.date?.concat("-01"),
                limit: createMonthlyLimit?.limit,
                status: createMonthlyLimit?.status,
            }
            dispatch(AddMonthlyLimit({ body: data, callback: () => setLoading(false) }))
            setOpen(false)
            setCreateMonthlyLimit({
                "date": "",
                "limit": "",
            })
            setLoading(true)
        }
    }

    const handleEid = () => {
        let valid = Validator(editMonthlyLimit)
        if (valid) {

        const filterDate = ()=> {
            return MonthlyLimit.filter(item => item._id !== editMonthlyLimit?._id);
        }

        const momentDates = filterDate()?.map(item => {
            const formateDate = moment(item.date).format("YYYY-MM") 
            if(formateDate !== editMonthlyLimit?.date ){
                return "empty"
            }else{
                return formateDate
            }
        });

        if(momentDates.includes(editMonthlyLimit?.date)) return  MessageShow("error", "This Month Is Already Exist");
        const data = {
            date: editMonthlyLimit?.date?.concat("-01"),
            limit: editMonthlyLimit?.limit,
            status: editMonthlyLimit?.status,
        }
            dispatch(EditMonthlyLimit({id:editMonthlyLimit?._id, body: data, callback: () => setLoading(false) }))
            setEditMonthlyLimit({
                "date": "",
                "limit": "",
            })
            setEditOpen(false)
            setLoading(true)
        }
    }
    
    const GetButtons = (element, index) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="edit">
                    <i className="bx bx-edit"></i>
                </a>
            </li> <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>)
    }

    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Monthly Subscription Limit</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Monthly Subscription Limit</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">

                        <div className="btn-group">
                            {Loading ? <Button text="Creating..." className="d-flex align-items-center btn btn-white font-18" icon={<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} />
                                : <Button text="Add Monthly Limit" onClick={() => {
                                    setOpen(true)
                                }} className="d-flex align-items-center btn btn-primary font-18" icon={<i className="bx bx-plus ms-1" />} />}
                        </div>

                    </div>
                </div>
                <hr />

                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data ? <div className="table-responsive">
                                    <Table {...LIST} callback={(e, type) => {
                                        if (type == 'edit') {
                                            setEditOpen(true)
                                            setEditMonthlyLimit({...e,date:moment(e?.date).format("YYYY-MM")})
                                        } else {
                                            setDeleteMonthlyLimit(e)
                                        }
                                    }} />
                                    <Modal data={LIST.data} field={LIST.columns} SubmitAPI="/api-url" />
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Monthly Limit Modal */}
                <ReactModal open={(open)} onClose={() =>{setOpen(false)}} title={'Add Monthly Limit'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Date</label>
                            <input type={'month'} min={moment().format("YYYY-MM")}  name={'date'} onChange={(e) => setCreateMonthlyLimit((item) => ({ ...item, date: e.target.value }))} className="form-control" value={createMonthlyLimit?.date} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Limit</label>
                            <input type={'number'} pattern="(?!00)\d+" name={'number'} min={1} onChange={(e) => setCreateMonthlyLimit((item) => ({ ...item, limit: e.target.value }))} className="form-control" value={createMonthlyLimit?.limit} placeholder='Enter Limit' />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleSend} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* Edit Monthly Limit Modal */}
                <ReactModal open={(editOpen)} onClose={() =>{setEditOpen(false)}} title={'Edit Monthly Limit'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Date</label>
                            <input type={'month'} min={moment().format("YYYY-MM")} name={'date'} onChange={(e) => setEditMonthlyLimit((item) => ({ ...item, date: e.target.value }))} className="form-control" placeholder='Enter Month' value={editMonthlyLimit?.date} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Limit</label>
                            <input type={'number'} min={1} placeholder='Enter Limit' name={'limit'} onChange={(e) => setEditMonthlyLimit((item) => ({ ...item, limit: e.target.value }))} className="form-control" value={editMonthlyLimit?.limit} />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleEid} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* Delete Monthly Subscription Modal */}
                <ReactModal open={deleteMonthlyLimit} title='Confirmation Alert!' onClose={() => setDeleteMonthlyLimit(false)} >
                    <div className="modal-body">
                        <div className="create_noti_form">
                            <h5>Do you want to delete monthly limit?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setDeleteMonthlyLimit(false)} data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-danger" onClick={() => { dispatch(DeleteMonthlyLimit(deleteMonthlyLimit?._id)); setDeleteMonthlyLimit(false) }} data-bs-dismiss="modal">Yes</button>
                    </div>
                </ReactModal>

            </div>
        </div>
    )
}

export default MonthlySubscriptionLimit