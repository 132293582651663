import React, { useEffect, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import Button from '../components/Button';
import ReactModal from '../components/ReactModal';
import Table from '../components/Table';
import { MessageShow, Validator } from '../Utils/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { AddTutorial, DeleteTutorial,EditTutorial } from '../reducer/Auth';
import Modal from '../components/Modal';
import Select from 'react-select'

const ProductTutorial = () => {
    const [open, setOpen] = useState(false)
    const [editOpen,setEditOpen] = useState(false)
    const GetButtons = (element, index) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="edit">
                    <i className="bx bx-edit"></i>
                </a>
            </li> <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>)
    }
    const { ProductList,Tutorial } = useSelector(State => State.Auth)
    const [LIST, setLIST] = useState({})
   
    const [deleteTutorialModal, setDeleteTutorialModal] = useState(false)
    const [tutorialProduct,setTutorialProduct] = useState([])
    const [options, setOptions] = useState([])
    const [craeteNew, setCreateNew] = useState({
        "title": "",
        "thumbnail": "",
        "media": "",
        "description": "",
        "products": "",
    })
    const [editTutorialForm,setEditTutorialForm ] = useState({
        "title": "",
        "thumbnail": null,
        "media": null,
        "description": "",
        "product": "",
    })

    function breakString(inputString, spacesPerLine) {
        // Use regular expression to find every 10 spaces
        const regex = new RegExp(`(([^ ]* ){${spacesPerLine}}[^ ]*)`, 'g');

        // Replace matches with the matched substring followed by a newline
        const resultString = inputString.replace(regex, '$1<br>');

        return resultString;
    }

    useEffect(() => {
        if (Tutorial.length) {
            let table_data = {
                data: [],
                columns: [
                    { title: 'Thumbnail' },
                    { title: 'Title' },
                    { title: 'Description' },
                    // { title: 'overview details' },
                    { title: 'Videos' },
                    { title: 'Products' },
                    // { title: 'Cetgories' },
                    { title: 'Actions' }
                ],
            }
                Tutorial?.forEach(element => {
                const {
                    thumbnail,
                    title,
                    description,
                    // over_view,
                    media,
                    product,
                    categories
                } = element.item
                table_data.data.push([
                    `<img src="${thumbnail}" alt class="product-C-img" />`,
                    title || '- - -',
                    breakString(description, 6) || '- - -',
                    // breakString(over_view, 6) || '- - -',
                    // videos?.length || '- - -',
                    `<a href="${media}" target="_blank">View</a>` || '- - -',
                    // products?.map((item) => item?.title).join('</br>') || '- - -',
                    product[0].title|| '- - -',
                    GetButtons(element.item)
                ])
            });
            setLIST(table_data)
        }
    }, [Tutorial])

    useEffect(()=>{
        if(Tutorial.length){
            Tutorial?.map((item)=> tutorialProduct?.push(item.products[0].product_id) )
        }
    },[])


    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        // console.log("file",file);
        if (name === 'thumbnail' || name === 'media') {
          // Check if the file type is allowed (image for thumbnail, video for media)
          if ((name === 'thumbnail' && file.type.startsWith('image/')) || (name === 'media' && file.type.startsWith('video/'))) {
            setCreateNew((prevFormData) => ({
              ...prevFormData,
              [name]: file,
            }));
          } else {
            alert('Invalid file type. Please upload a valid image or video.');
          }
        }
      };
    const EditHandleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        if (name === 'thumbnail' || name === 'media') {
          if ((name === 'thumbnail' && file.type.startsWith('image/')) || (name === 'media' && file.type.startsWith('video/'))) {
            setEditTutorialForm((prevFormData) => ({
              ...prevFormData,
              [name]: file,
            }));
          } else {
            alert('Invalid file type. Please upload a valid image or video.');
          }
        }
    };

    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(false)
    const handleSend = async() => {        
        let valid = Validator(craeteNew)
        if (valid) {
            const formDataToSend = new FormData();
            formDataToSend.append('title', craeteNew.title);
            formDataToSend.append('thumbnail', craeteNew.thumbnail);
            formDataToSend.append('media', craeteNew.media);
            formDataToSend.append('description', craeteNew.description);
            formDataToSend.append('product[0][product_id]', craeteNew?.products?.value?.id);
            formDataToSend.append('product[0][title]', craeteNew.products?.value?.title);
            dispatch(AddTutorial({ body: formDataToSend, callback: () => setLoading(false) }))
            setCreateNew({
                "title": "",
                "thumbnail": "",
                "media": "",
                "description": "",
                "products": "",
            })
            setOpen(false)
            setLoading(true)
        }
    }

    const handleEid = () => {
            if(!editTutorialForm.title) return MessageShow("error", "Title is required");
            if(!editTutorialForm.description) return MessageShow("error", "Description is required");
            const formDataToSend = new FormData();
            formDataToSend.append('title', editTutorialForm.title);
            formDataToSend.append('thumbnail', editTutorialForm.thumbnail?.name ? editTutorialForm.thumbnail : null);
            formDataToSend.append('media', editTutorialForm?.media?.name ? editTutorialForm?.media : null);
            formDataToSend.append('description', editTutorialForm.description);
            formDataToSend.append('product[0][product_id]', editTutorialForm?.product?.value?.id);
            formDataToSend.append('product[0][title]', editTutorialForm.product?.value?.title);
            dispatch(EditTutorial({id:editTutorialForm?._id, body: formDataToSend, callback: () => setLoading(false) }))
            setEditOpen(false)
            setLoading(true)
    }



    useEffect(() => {
        if(!ProductList) return
        const filteredProductsData = ProductList?.filter(product =>!tutorialProduct.includes(product?.product_id) );

        if (filteredProductsData) {
            let LIST = []
            filteredProductsData?.forEach(PRODUCT => {
                LIST.push({ value: {title:PRODUCT.title, id:PRODUCT?.product_id}, label: `${PRODUCT.title} ` })
            });
            setOptions(LIST)
        }
    }, [ProductList])   

    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Product Tutorial</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Product Tutorial</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">

                        <div className="btn-group">
                            {Loading ? <Button text="Creating..." className="d-flex align-items-center btn btn-white font-18" icon={<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} />
                                : <Button text="Add Tutorial" onClick={() => {
                                    setOpen(true)
                                }} className="d-flex align-items-center btn btn-primary font-18" icon={<i className="bx bx-plus ms-1" />} />}
                        </div>

                    </div>
                </div>
                <hr />

                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data ? <div className="table-responsive">
                                    <Table {...LIST} callback={(e, type) => {
                                        // console.log(e)
                                        if (type == 'edit') {
                                            setEditOpen(true)
                                            setEditTutorialForm({...e,product:{label:e.product[0].title,value:{id:e.product[0].product_id,title:e.product[0].title}}})
                                            // console.log("edit",editTutorialForm);

                                        } else {
                                            setDeleteTutorialModal(e)
                                        }
                                    }} />
                                    <Modal data={LIST.data} field={LIST.columns} SubmitAPI="/api-url" />
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Tutorial Modal */}
                <ReactModal open={(open)} onClose={() =>{setOpen(false)}} title={'Add Tutorial'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Title</label>
                            <input type={'text'} name={'title'} placeholder='Enter Title' onChange={(e) => setCreateNew((item) => ({ ...item, title: e.target.value }))} className="form-control" value={craeteNew?.title} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Thumbnail</label>
                            <input accept="image/*" type={'file'} onChange={handleFileChange} name={'thumbnail'} className="form-control" />
                        </div>
                        <div className="col-md-12 mt-3">
                                <label htmlFor="input1" className="form-label">Video</label>
                                <input type={'file'} accept="video/mp4,video/x-m4v,video/*" onChange={handleFileChange} name={'media'} className="form-control" />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Description</label>
                            <textarea type={'text'} name={'description'} onChange={(e) => setCreateNew((item) => ({ ...item, description: e.target.value }))} className="form-control" value={craeteNew?.description} placeholder='Enter Description' />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Products</label>
                            <Select  options={options}
                                value={craeteNew.products}
                                onChange={(value) => {
                                    setCreateNew((item) => ({ ...item, products: value }))
                                }}
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleSend} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* Edit Tutorial Modal */}
                <ReactModal open={(editOpen)} onClose={() =>{setEditOpen(false)}} title={'Edit Tutorial'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Title</label>
                            <input type={'text'} name={'title'} onChange={(e) => setEditTutorialForm((item) => ({ ...item, title: e.target.value }))} className="form-control" placeholder='Enter Title' value={editTutorialForm?.title} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Thumbnail</label>
                            {!editTutorialForm?.thumbnail?.name ? <img accept="image/*" style={{objectFit:"cover",display:"block",width:"100%"}} width={500} height={450} src={editTutorialForm?.thumbnail} alt class="product-C-img" /> : null}
                            <input accept="image/*" type={'file'} onChange={EditHandleFileChange} name={'thumbnail'} className="form-control" />
                        </div>
                        <div className="col-md-12 mt-3">
                                <label htmlFor="input1" className="form-label">Video</label>
                            {!editTutorialForm?.media?.name ? <video src={editTutorialForm.media} width={"100%"} height={200} controls /> : null}
                                <input type={'file'} accept="video/mp4,video/x-m4v,video/*" onChange={EditHandleFileChange} name={'media'} className="form-control" />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Description</label>
                            <textarea type={'text'} placeholder='Enter Description' name={'description'} onChange={(e) => setEditTutorialForm((item) => ({ ...item, description: e.target.value }))} className="form-control" value={editTutorialForm?.description} />
                        </div>
                        
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Products</label>
                            <Select  options={options}
                                value={editTutorialForm?.product}
                                onChange={(value) => {
                                    // console.log("value",value.value);
                                    setEditTutorialForm((item) => ({ ...item, product:value }))
                                    // console.log("change",editTutorialForm.product[0].title);
                                }}
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleEid} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* Delete Tutorial Modal */}
                <ReactModal open={deleteTutorialModal} title='Confirmation Alert!' onClose={() => setDeleteTutorialModal(false)} >
                    <div className="modal-body">
                        <div className="create_noti_form">
                            <h5>Do you want to delete {deleteTutorialModal?.title} Tutorial?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setDeleteTutorialModal(false)} data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-danger" onClick={() => { dispatch(DeleteTutorial(deleteTutorialModal?._id)); setDeleteTutorialModal(false) }} data-bs-dismiss="modal">Yes</button>
                    </div>
                </ReactModal>
            </div>
        </div>

    )
}

export default ProductTutorial