import React from 'react'

const Modal_Actions = (props) => {
    return (
        <>
            <ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
                {
                    props.view ? <li>
                        <a href="javaScript:;" data-id={props.id} data-type={'view'}>
                            <i className="lni lni-eye"></i>
                        </a>
                    </li> : false
                }
                {
                    props.delete ? <li>
                        <a href="javaScript:;" data-id={props.id} data-type={'delete'}>
                            <i className="bx bx-trash"></i>
                        </a>
                    </li> : false
                }
                {
                    props.edit ? <li>
                        <a href="javaScript:;" data-id={props.id} data-type={'edit'}>
                            <i className="bx bx-edit"></i>
                        </a>
                    </li> : false
                }
                {
                    props.block ? <li>
                        <a href="javaScript:;" data-id={props.id} data-type={'block'}>
                            <i className="bx bx-block"></i>
                        </a>
                    </li> : false
                }
            </ul>
        </>
    )
}

export default Modal_Actions