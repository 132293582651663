import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MessageShow, showResponseError } from '../Utils/Constant';
import { API_URLS } from '../Utils/EndPoints';
import { AuthPOST, GET, POST } from '../Utils/API';

const initialState = {
    User: {},
    token: null,
    UserList: [],
    ProductList: [],
    Reels: [],
    Course: {},
    Tutorial:{},
    TutorialCategories:{},
    Subscription: [],
    DashboardData: {},
    Staff: [],
    Notifications: [],
    SubscriptionFeature:[],
    SubscriptionTitle:{},
    MonthlyLimit:{}
}

export const getStats = createAsyncThunk("GetStatsData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.STATS)
        thunk.dispatch(Auth.actions.setDashboard(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});



export const getUser = createAsyncThunk("GetUserData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.ALL_USER)
        thunk.dispatch(Auth.actions.setUsers(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


export const GetProducts = createAsyncThunk("GetProductsData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_PRODUCTS)
        thunk.dispatch(Auth.actions.setProductList(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const GetReels = createAsyncThunk("GetReelsData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.REELS)
        thunk.dispatch(Auth.actions.setReels(response.data.items));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const UpdateReels = createAsyncThunk("UpdateReelsData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPDATE_REELS, body)
        MessageShow('success', response.message)
        thunk.dispatch(GetReels());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

// Subscription Feature
export const GetFeatureTitle = createAsyncThunk("GetFeatureTitleData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_SUBSCRIPTION_TITLE)
        thunk.dispatch(Auth.actions.getSubscriptionTitle(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


export const GetSubscription = createAsyncThunk("GetSubscriptionData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_SUBSCRIPTION_FEATURE)
        thunk.dispatch(Auth.actions.getSubscription(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const AddSubscription = createAsyncThunk("AddSubscriptionData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.ADD_SUBSCRIPTION_FEATURE, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetSubscription());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const DeleteSubscription = createAsyncThunk("DeleteSubscriptionData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_SUBSCRIPTION_FEATURE + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetSubscription());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const EditSubscription = createAsyncThunk("EditSubscriptionData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPDATE_SUBSCRIPTION_FEATURE + body.id, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetSubscription());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
    // Subscription Title
export const GetSubscriptionTitle = createAsyncThunk("GetSubscriptionTitleData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_SUBSCRIPTION_TITLE)
        thunk.dispatch(Auth.actions.getSubscriptionTitle(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const EditSubscriptionTitle = createAsyncThunk("EditSubscriptionTitleData", async (body, thunk) => {
 
    try {
        const response = await AuthPOST(API_URLS.UPDATE_SUBSCRIPTION_TITLE + body.id, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetSubscriptionTitle());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

// Subscription Membership delete
export const DeleteSubscriptionData = createAsyncThunk("DeleteSubscriptionData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_SUBSCRIPTION + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetMemebership());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


// API Monthly Subscription Limit 
export const GetMonthlyLimit = createAsyncThunk("GetMonthlyLimitData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_MONTHLY_LIMIT)
        thunk.dispatch(Auth.actions.getMonthlyLimit(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const AddMonthlyLimit = createAsyncThunk("AddMonthlyLimitData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.ADD_MONTHLY_LIMIT, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetMonthlyLimit());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const EditMonthlyLimit = createAsyncThunk("EditMonthlyLimitData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPDATE_MONTHLY_LIMIT + body.id, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetMonthlyLimit());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const DeleteMonthlyLimit = createAsyncThunk("DeleteMonthlyLimitData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_MONTHLY_LIMIT + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetMonthlyLimit());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

// AuthPOST
    // Add tutorial


// export const AddTutorial = createAsyncThunk("AddTutorialData", async (body, thunk) => {
//     try {
//         const response = await AuthPOST(API_URLS.ADD_TUTORIAL, body.body, true)
//         MessageShow('success', response.message)
//         body?.callback()
//         thunk.dispatch(AddTutorial());
//         return thunk.fulfillWithValue(response);
//     } catch (error) {
//         body?.callback()
//         let err = MessageShow("error", showResponseError(error));
//         return thunk.rejectWithValue(err);
//     }
// });
export const GetTutorial = createAsyncThunk("GetTutorialData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_TUTORIAL)
        thunk.dispatch(Auth.actions.getTutorial(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const DeleteTutorial = createAsyncThunk("DeleteTutorialData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_TUTORIAL + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetTutorial());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const GetTutorialCategories = createAsyncThunk("GetTutorialCategoriesData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.TUTORIAL_CATEGORIES)
        thunk.dispatch(Auth.actions.getTutorialCategories(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const AddTutorial = createAsyncThunk("AddTutorialData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.ADD_TUTORIAL, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetTutorial());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const EditTutorial = createAsyncThunk("EditTutorialData", async (body, thunk) => {
 
    try {
        const response = await AuthPOST(API_URLS.UPDATE_TUTORIAL + body.id, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetTutorial());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const AddCourse = createAsyncThunk("AddCourseData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPLOAD_COURSE, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const DeleteCourseOverview = createAsyncThunk("DeleteCourseOverviewData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_COURSE_OVERVIEW + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
// Delete course Categories
export const DeleteCourseCategories = createAsyncThunk("DeleteCourseCategoriesData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_COURSE_CATEGORIES + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
// Delete Course Products
export const DeleteCourseProducts = createAsyncThunk("DeleteCourseProductsData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_COURSE_PRODUCTS + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
// Delete Course 
export const DeleteCourseLesson = createAsyncThunk("DeleteCourseLessonData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_COURSE_LESSON + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const EditCourse = createAsyncThunk("EditCourseData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.EDIT_COURSE, body.body, true)
        MessageShow('success', response.message)
        body?.callback()
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const DeleteCourse = createAsyncThunk("DeleteCourseData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_COURSE + body)
        MessageShow('success', response.message)
        thunk.dispatch(GetCourse());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        body?.callback()
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


export const AddCategry = createAsyncThunk("AddCategryData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.CATE, body?.body, true)
        MessageShow('success', response.message)
        thunk.dispatch(Auth.actions.addFilter(response.data));
        body?.callback()
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const GetCourse = createAsyncThunk("GetCourseData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_COURSE)
        thunk.dispatch(Auth.actions.setCourse(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


export const GetMemebership = createAsyncThunk("GetMemebershipData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.MEMBERSHIP)
        thunk.dispatch(Auth.actions.setSubscription(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const GetDeleteCate = createAsyncThunk("DeleteCateData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.CATE_DELETE + body)
        thunk.dispatch(Auth.actions.addFilter(response.data));
        MessageShow('success', response.message)
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const GetStaff = createAsyncThunk("GetStaffData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_STAFF)
        thunk.dispatch(Auth.actions.setStaff(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const GetDeleteStaff = createAsyncThunk("DeleteStaff", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_STAFF + body)
        thunk.dispatch(Auth.actions.setStaff(response.data));
        MessageShow('success', response.message)
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const UpdateStaff = createAsyncThunk("UpdateStaff", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPDATE_STAFF, body)
        MessageShow('success', response.message)
        thunk.dispatch(Auth.actions.setStaff(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const AddStaff = createAsyncThunk("AddStaff", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.ADD_STAFF, body?.body, true)
        body?.callback()
        MessageShow('success', response.message)
        thunk.dispatch(Auth.actions.setStaff(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


export const GetNotifications = createAsyncThunk("GetNotificationsData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.GET_NOTIFICATIONS)
        thunk.dispatch(Auth.actions.setNotification(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const GetDeleteNotification = createAsyncThunk("GetDeleteNotificationData", async (body, thunk) => {
    try {
        const response = await GET(API_URLS.DELETE_NOTIFICATIONS + body)
        thunk.dispatch(Auth.actions.setNotification(response.data));
        MessageShow('success', response.message)
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const GetAddNotification = createAsyncThunk("GetAddNotification", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.SENT_NOTIFICATIONS, body?.body, true)
        body?.callback()
        MessageShow('success', response.message)
        thunk.dispatch(GetNotifications());
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});



export const UpdateProfile = createAsyncThunk("UpdateProfile", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPDATE_PROFILE, body)
        MessageShow('success', response.message)
        thunk.dispatch(Auth.actions.setUserData(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});

export const UpdateProfileImage = createAsyncThunk("UpdateProfileImage", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.UPDATE_PROFILE_IMAGE, body, true)
        MessageShow('success', response.message)
        thunk.dispatch(Auth.actions.setUserData(response.data));
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});
export const ChangePassword = createAsyncThunk("ChangePasswordData", async (body, thunk) => {
    try {
        const response = await AuthPOST(API_URLS.CHANGE_PASSWORD, body)
        MessageShow('success', response.message)
        localStorage.removeItem('persist:root');
        window.location.replace('/auth-cover-signin')
        return thunk.fulfillWithValue(response);
    } catch (error) {
        let err = MessageShow("error", showResponseError(error));
        return thunk.rejectWithValue(err);
    }
});


export const Auth = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.User = action.payload
        },
        setUserToken: (state, action) => {
            state.token = action.payload
        },
        setUsers: (state, action) => {
            let payload = action.payload;
            state.UserList = payload
        },
        setProductList: (state, action) => {
            let payload = action.payload;
            let list = []

            payload?.forEach(element => {
                const {
                    variants,
                } = element
                list.push({
                    ...element,
                    Category: variants?.map((item) => item.formatted_price)?.join('</br>') || '- - -',
                    CategoryId: variants?.map((item) => item.title)?.join('</br>') || '- - -',
                    ProductId: variants?.map((item) => item.id)?.join('</br>') || '- - -',
                })
            });
            state.ProductList = list
        },
        setReels: (state, action) => {
            let payload = action.payload;
            state.Reels = payload
        },
        setCourse: (state, action) => {
            let payload = action.payload;
            state.Course = payload
        },
        getTutorial: (state, action) => {
            let payload = action.payload;
            state.Tutorial = payload
        },
        getTutorialCategories: (state, action) => {
            let payload = action.payload;
            state.TutorialCategories = payload
        },
        setSubscription: (state, action) => {
            let payload = action.payload;
            state.Subscription = payload
        },
        setDashboard: (state, action) => {
            let payload = action.payload;
            state.DashboardData = payload
        },
        addFilter: (state, action) => {
            let payload = action.payload;
            state.Course = { ...state.Course, Filters: payload }
        },
        setStaff: (state, action) => {
            let payload = action.payload;
            state.Staff = payload
        },
        setNotification: (state, action) => {
            let payload = action.payload;
            state.Notifications = payload
        },
        // Subscription Feature
        getSubscription: (state, action) => {
            let payload = action.payload;
            state.SubscriptionFeature = payload
        },
        getSubscriptionTitle: (state, action) => {
            let payload = action.payload;
            state.SubscriptionTitle = payload
        },
        // Monthly Subscription Limit
        getMonthlyLimit: (state, action) => {
            let payload = action.payload;
            state.MonthlyLimit = payload
        },
        
    },
})
export const getAccessToken = (state) => {
    return state.Auth.token;
};

// Action creators are generated for each case reducer function
export const { setUserData, setUserToken } = Auth.actions

export default Auth.reducer