import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';
import { API_URLS } from '../Utils/EndPoints';
import { GET } from '../Utils/API';
import { MessageShow } from '../Utils/Constant';
import { useSelector } from 'react-redux';

const Users_Managment = () => {
    const GetButtons = (id) => {
        return ReactDomServer.renderToString(<Modal_Actions edit view delete id={id} />)
    }




    let table_data = {
        data: [
            // ['<img src="assets/images/avatars/avatar-3.png" alt class="product-img-2" />', "Hilary", 'Swank', 'Hilary@gmail.com', '(123)-456-7890', '9497 The Avenue SOUTHALL UB92 2YF', '9497 The Avenue SOUTHALL UB92 2YF', GetButtons(0)],
            // ['<img src="assets/images/avatars/avatar-3.png" alt class="product-img-2" />', "Hilary", 'Swank', 'Hilary@gmail.com', '(123)-456-7890', '9497 The Avenue SOUTHALL UB92 2YF', '9497 The Avenue SOUTHALL UB92 2YF', GetButtons(1)],
            // ['<img src="assets/images/avatars/avatar-3.png" alt class="product-img-2" />', "Hilary", 'Swank', 'Hilary@gmail.com', '(123)-456-7890', '9497 The Avenue SOUTHALL UB92 2YF', '9497 The Avenue SOUTHALL UB92 2YF', GetButtons(2)],
            // ['<img src="assets/images/avatars/avatar-3.png" alt class="product-img-2" />', "Hilary", 'Swank', 'Hilary@gmail.com', '(123)-456-7890', '9497 The Avenue SOUTHALL UB92 2YF', '9497 The Avenue SOUTHALL UB92 2YF', GetButtons(3)],
            // ['<img src="assets/images/avatars/avatar-3.png" alt class="product-img-2" />', "Hilary", 'Swank', 'Hilary@gmail.com', '(123)-456-7890', '9497 The Avenue SOUTHALL UB92 2YF', '9497 The Avenue SOUTHALL UB92 2YF', GetButtons(4)],
        ],
        columns: [
            { title: 'First Name' },
            { title: 'Last Name' },
            { title: 'Email' },
            { title: 'Phone No' },
            { title: 'Shopify ID' },
            { title: 'Points' },
            // { title: 'Shipping Address' },
            // { title: 'Actions' }
        ],
    }

    const [LIST, setLIST] = useState({})

    const { UserList } = useSelector((State) => State.Auth)

    useEffect(() => {
        if (UserList) {

            let table_data = {
                data: [],
                columns: [
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Email' },
                    { title: 'Phone No' },
                    { title: 'Shopify ID' },
                    { title: 'Points' },
                ],
            }
            UserList?.forEach(element => {
                const {
                    email,
                    firstName,
                    id,
                    lastName,
                    points,
                    phone,
                } = element
                table_data.data.push([
                    firstName || '- - -',
                    lastName || '- - -',
                    email || '- - -',
                    phone || '- - -',
                    id || '- - -',
                    points || '- - -',
                ])
            });
            setLIST(table_data)

        }
    }, [UserList])

    const modal_fields = [
        {
            type: 'file',
            name: 'profile_pic',
            label: 'Profile Pic',
        },
        {
            type: 'text',
            name: 'first_name',
            label: 'First Name',
        },
        {
            type: 'text',
            name: 'last_name',
            label: 'Last Name',
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email',
        },
        {
            type: 'phone',
            name: 'phone',
            label: 'Phone',
        },
        {
            type: 'text',
            name: 'billing_address',
            label: 'Billing Address',
        },
        {
            type: 'text',
            name: 'shipping_address',
            label: 'Shipping Address',
        }
    ]

    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Users Management</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Users Management</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        {/* <Modal data={modal_fields.}  create/> */}
                    </div>
                </div>
                <hr />
                <div className="card user-table">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data?.length ? <div className="table-responsive">
                                    <Table {...LIST} />
                                    {/* <Modal data={LIST.data}  /> */}
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Users_Managment