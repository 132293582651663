import React from 'react'

const NotFound = () => {
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <h1>404</h1>
            </div>
        </div>
    )
}

export default NotFound