import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../components/Button';
import ReactModal from '../components/ReactModal';
import { GetAddNotification, GetDeleteNotification } from '../reducer/Auth';
import { MessageShow, Validator } from '../Utils/Constant';


const Admin_Notifications = () => {
    const GetButtons = (element, index) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-id={index} data-type={'view'}>
                    <i className="lni lni-eye"></i>
                </a>
            </li> <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>)
    }

    const [LIST, setLIST] = useState({})
    const [openDelete, setDeleteModal] = useState(false)
    const [openAdd, setAddModal] = useState(false)
    const [Loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const { Notifications } = useSelector((State) => State.Auth)

    useEffect(() => {
        if (Notifications) {
            console.log("Notifications",Notifications);

            let table_data = {
                data: [],
                columns: [
                    { title: 'Title' },
                    { title: 'Message' },
                    { title: 'Date' },
                    { title: 'Status' },

                    { title: 'Actions' }
                ],
            }
            Notifications?.forEach((element, index) => {
                const {
                    title,
                    message
                } = element?.message?.data
                const {status} = element

                table_data.data.push([
                    title || '- - -',
                    message || '- - -',
                    moment(element?.createdAt).format('LLLL') || '- - -',
                    status === true ? "Pending" : "Delivered" || "----",
                    GetButtons(element, index)
                ])
            });
            setLIST(table_data)

        }
    }, [Notifications])

    const modal_fields = [
        {
            type: 'text',
            name: 'Title',
            label: 'Subject',
        },
        {
            type: 'text',
            name: 'Message',
            label: 'Message',
        },
        {
            type: 'date',
            name: 'Date',
            label: 'Date',
        }
    ]
    

    const handleAdd = (e) => {
        e.preventDefault();
        const { title, message,time,date } = openAdd
        let vali = Validator({ title: title || '', message: message || '' })
        if (vali) {
            if(date){
                if(!time)return MessageShow("error", "Time is required");
                const inputDate = `${date} ${time}`
                const isoFormate = moment(inputDate).toISOString()
                dispatch(GetAddNotification({ body: { title, message, date:isoFormate }, callback: () => { setLoading(false) } }))
                setAddModal(false)
                setLoading(true)
            }else{
                dispatch(GetAddNotification({ body: { title, message }, callback: () => { setLoading(false) } }))
                setAddModal(false)
                setLoading(true)
            }
        }
    }

    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3 d-none d-xl-block d-lg-block d-md-block">Push Notifications</div>
                    <div className="ps-3 d-none d-xl-block d-lg-block d-md-block">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Push Notifications</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        <div className="btn-group">
                            <div className="btn-group">
                                {Loading ? <Button text="Creating..." className="d-flex align-items-center btn btn-white font-18" icon={<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} />
                                    : <Button text="Send Notification" onClick={() => {
                                        setAddModal(true)
                                    }} className="d-flex align-items-center btn btn-primary font-18" icon={<i className="bx bx-plus ms-1" />} />}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data ? <div className="table-responsive">
                                    <Table {...LIST} callback={(data, type) => {
                                        if (type === 'delete') {
                                            setDeleteModal(data)
                                        }
                                    }} />
                                    <Modal data={LIST.data} field={modal_fields} SubmitAPI="/api-url" />
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>

                  {/* Delete Notification */}
                <ReactModal open={openDelete} onClose={() => setDeleteModal(false)} title='Confirmation Alert!' >
                    <div className="modal-body">
                        <div className="create_noti_form">
                            <h5>Do you want to delete this?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setDeleteModal(false)}>No</button>
                        <button type="button" className="btn btn-danger" onClick={() => { dispatch(GetDeleteNotification(openDelete?._id)); setDeleteModal(false) }} data-bs-dismiss="modal">Yes</button>
                    </div>
                </ReactModal>

                {/* <ReactModal open={openAdd} onClose={() => setAddModal(false)} title='Notification' >
                    <div className="modal-body">
                        <div className="profile_modal_wrap">
                            <div className="main-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <form id="style-3" >
                                                    <div className="col-md-12">
                                                        <label htmlFor="input1" className="form-label">Title</label>
                                                        <input type={'text'} value={openAdd.title} onChange={(e) => { setAddModal(data => ({ ...data, title: e.target.value })) }}  className="form-control" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label htmlFor="input1" className="form-label">Message</label>
                                                        <textarea type={'text'} value={openAdd.message} onChange={(e) => { setAddModal(data => ({ ...data, message: e.target.value })) }} className="form-control" />
                                                    </div>
                                                    <div style={{display:'flex',justifyContent:"center", gap:"10px"}}>

                                                    <div className="col-md-6">
                                                        <label htmlFor="input1" className="form-label">Time</label>
                                                        <input type={'time'} value={openAdd.data} onChange={(e) => { setAddModal(data => ({ ...data, time: e.target.value })) }}  className="form-control" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="input1" className="form-label">Date</label>
                                                        <input type={'date'} value={openAdd.data} onChange={(e) => { setAddModal(data => ({ ...data, time: e.target.value })) }}  className="form-control" />
                                                    </div>
                                                    </div>


                                                    <div className="row mt-3">
                                                        <div className="col-sm-9 text-secondary">
                                                            <Button className="btn btn-primary" onClick={handleAdd} text="Save Changes" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal> */}
                {/* Send Notification */}
                <ReactModal open={(openAdd)} onClose={() => { setAddModal(false) }} title={'Send Notification'}  >
                    <div class="modal-body">
                        <div className="col-md-12">
                            <label htmlFor="input1" className="form-label">Title</label>
                            <input type={'text'} value={openAdd.title} onChange={(e) => { setAddModal(data => ({ ...data, title: e.target.value })) }} className="form-control" />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Message</label>
                            <textarea type={'text'} value={openAdd.message} onChange={(e) => { setAddModal(data => ({ ...data, message: e.target.value })) }} className="form-control" />
                        </div>
                        <div className='mt-3 col-md-12  row' style={{flexWrap:"wrap"}}>
                            <div className="col-md-6">
                                <label htmlFor="input1" className="form-label">Date</label>
                                <input type={'date'} value={openAdd.data} min={moment().format("YYYY-MM-DD")} onChange={(e) => { setAddModal(data => ({ ...data, date: e.target.value })) }} className="form-control" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="input1"  className="form-label">Time</label>
                                <input type={'time'} disabled={openAdd?.date ? false :true} value={openAdd?.date ? openAdd?.data : ""} onChange={(e) => { setAddModal(data => ({ ...data, time: e.target.value })) }} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleAdd} text="Save Changes" />
                    </div>
                </ReactModal>

            </div>
        </div>

    )
}

export default Admin_Notifications