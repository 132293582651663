import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from '../components/ReactModal';
import { AddStaff, GetDeleteStaff, UpdateStaff } from '../reducer/Auth';
import Button from '../components/Button';
import { Validator } from '../Utils/Constant';
import Select from 'react-select'
const Role_Management = () => {
    const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

    const GetButtons = (element) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="edit" >
                    <i className="bx bx-edit"></i>
                </a>
            </li>
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>)
    }
    const { Staff, User } = useSelector((STATE) => STATE.Auth)

    const [AdminUser, setAdminUser] = useState({})
    const [SubAdminUser, setSubAdminUser] = useState({})

    useEffect(() => {
        if (Staff) {

            let table_data = {
                data: [],
                columns: [
                    { title: 'Profile Picture' },
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Email' },
                    { title: 'Phone No' },
                    { title: 'Actions' }
                ],
            }
            let sub_table_data = {
                data: [],
                columns: [
                    { title: 'Profile Picture' },
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Email' },
                    { title: 'Phone No' },
                    { title: 'Actions' }
                ],
            }
            Staff?.forEach((element, index) => {
                const {
                    profile,
                    email,
                    firstName,
                    id,
                    lastName,
                    points,
                    phone,
                    userType
                } = element
                if (userType === 'admin') {
                    table_data.data.push([
                        `<img src=${profile} class="product-img-2" alt="product img">`,
                        firstName || '- - -',
                        lastName || '- - -',
                        email || '- - -',
                        phone || '- - -',
                        GetButtons(element)
                    ])
                } else {
                    sub_table_data.data.push([
                        `<img src=${profile} class="product-img-2" alt="product img">`,
                        firstName || '- - -',
                        lastName || '- - -',
                        email || '- - -',
                        phone || '- - -',
                        GetButtons(element)
                    ])
                }
            });
            setAdminUser(table_data)
            setSubAdminUser(sub_table_data)
        }
    }, [Staff])





    const modal_fields = [
        {
            type: 'file',
            name: 'Profile Picture',
            label: 'Profile Picture',
        },
        {
            type: 'text',
            name: 'First Name',
            label: 'First Name',
        },
        {
            type: 'text',
            name: 'Last Name',
            label: 'Last Name',
        },
        {
            type: 'email',
            name: 'Email',
            label: 'Email',
        },
        {
            type: 'phone',
            name: 'Phone No',
            label: 'Phone No',
        },
    ]


    const [openDelete, setDeleteModal] = useState(false)
    const [openEdit, setEditModal] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [show, setshow] = useState(false)
    const [openAdd, setAddModal] = useState(false)

    const dispatch = useDispatch()


    const handleEdit = (e) => {
        e.preventDefault();
        const { firstName, lastName, phone, _id } = openEdit
        let vali = Validator({ 'First Name': firstName || '', 'Last Name': lastName || '', phone: phone || '' })
        if (vali) {
            dispatch(UpdateStaff({ firstName, lastName, phone, _id }))
            setEditModal(false)
        }
    }
    const handleAdd = (e) => {
        e.preventDefault();
        const { firstName, lastName, phone, email, profile, role, password } = openAdd
        let vali = Validator({ 'Profile Pic': profile || '', 'First Name': firstName || '', 'Last Name': lastName || '', phone: phone || '', Email: email || '', password: password || '', role: role || '' })
        if (vali) {
            let formdata = new FormData()
            formdata.append(`firstName`, firstName)
            formdata.append(`lastName`, lastName)
            formdata.append(`phone`, phone)
            formdata.append(`email`, email)
            formdata.append(`profile`, profile)
            formdata.append(`roleName`, role)
            formdata.append(`password`, password)
            dispatch(AddStaff({ body: formdata, callback: () => { setAddModal(false) } }))
        }
    }

    const Profile = (event) => {
        var reader = new FileReader();
        reader.onload = async function () {
            let isMediaAdd = event.target.files[0]
            setAddModal((add) => ({ ...add, profile: isMediaAdd }))
        };
        reader.readAsDataURL(event.target.files[0]);
    };


    useEffect(() => {
        // User?.userType
        if (User?.userType) {
            if (User?.userType !== 'admin') {
                window.location.replace('/')
            }
        }
    }, [User])
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Roles Management</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Roles Management</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        {Loading ? <Button text="Creating..." className="d-flex align-items-center btn btn-white font-18" icon={<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} />
                            : <Button text="Add Staff" onClick={() => {
                                // setOpen(true)
                                setAddModal({})
                            }} className="d-flex align-items-center btn btn-primary font-18" icon={<i className="bx bx-plus ms-1" />} />}
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-primary" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" data-bs-toggle="tab" href="#adminTab" role="tab" aria-selected="true">
                                    <div className="d-flex align-items-center">
                                        <div className="tab-icon font-18"><i className="lni lni-user  me-1" />
                                        </div>
                                        <div className="tab-title">Admin</div>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" data-bs-toggle="tab" href="#subAdminTab" role="tab" aria-selected="false">
                                    <div className="d-flex align-items-center">
                                        <div className="tab-icon font-18"><i className="lni lni-users  me-1" />
                                        </div>
                                        <div className="tab-title">Sub Admin</div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="adminTab" role="tabpanel">
                                {AdminUser?.data ? < div className="table-responsive">
                                    <Table {...AdminUser} callback={(data, type) => {
                                        console.log(data, type)
                                        if (type === 'delete') {
                                            setDeleteModal(data)
                                        }
                                        if (type === 'edit') {
                                            setEditModal(data)
                                        }
                                    }} />
                                    {/* <Modal data={AdminUser.data} field={modal_fields} callback={(data, type) => { console.log(data,type) }} /> */}
                                </div> : null}
                            </div>
                            <div className="tab-pane fade" id="subAdminTab" role="tabpanel">
                                {SubAdminUser?.data ? < div className="table-responsive">
                                    <Table {...SubAdminUser}
                                        T_name={'sub-admin'}
                                        callback={(data, type) => {
                                            console.log(data, type)
                                            if (type === 'delete') {
                                                setDeleteModal(data)
                                            }
                                            if (type === 'edit') {
                                                setEditModal(data)
                                            }
                                        }} />
                                    {/* <Modal data={AdminUser.data} field={modal_fields} callback={(data, type) => { console.log(data,type) }} /> */}
                                </div> : null}
                            </div>
                        </div>


                        <ReactModal open={openDelete} onClose={() => setDeleteModal(false)} title='Confirmation Alert!' >
                            <div className="modal-body">
                                <div className="create_noti_form">
                                    <h5>Do you want to delete {openDelete?.firstName} {openDelete?.lastName} account?</h5>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                <button type="button" className="btn btn-danger" onClick={() => { dispatch(GetDeleteStaff(openDelete?._id)); setDeleteModal(false) }} data-bs-dismiss="modal">Yes</button>
                            </div>
                        </ReactModal>
                        <ReactModal open={openEdit} onClose={() => setEditModal(false)} >
                            <div className="modal-body">
                                <div className="profile_modal_wrap">
                                    <div className="main-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form id="style-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">First Name</label>
                                                                <input type={'text'} value={openEdit.firstName} onChange={(e) => { setEditModal(data => ({ ...data, firstName: e.target.value })) }} id className="form-control" />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">Last Name</label>
                                                                <input type={'text'} value={openEdit.lastName} onChange={(e) => { setEditModal(data => ({ ...data, lastName: e.target.value })) }} className="form-control" />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">Phone</label>
                                                                <input type={'phone'} value={openEdit.phone} onChange={(e) => { setEditModal(data => ({ ...data, phone: e.target.value })) }} className="form-control" />
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-sm-9 text-secondary">
                                                                    <Button className="btn btn-primary" onClick={handleEdit} text="Save Changes" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ReactModal>


                        <ReactModal open={openAdd} onClose={() => setAddModal(false)} >
                            <div className="modal-body">
                                <div className="profile_modal_wrap">
                                    <div className="main-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form id="style-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">Profil Image</label>
                                                                <input type={'file'} accept="image/*" id className="form-control" onChange={Profile} />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">First Name</label>
                                                                <input type={'text'} value={openAdd.firstName} onChange={(e) => { setAddModal(data => ({ ...data, firstName: e.target.value })) }} id className="form-control" />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">Last Name</label>
                                                                <input type={'text'} value={openAdd.lastName} onChange={(e) => { setAddModal(data => ({ ...data, lastName: e.target.value })) }} className="form-control" />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">Email</label>
                                                                <input type={'text'} value={openAdd.email} onChange={(e) => { setAddModal(data => ({ ...data, email: e.target.value })) }} id className="form-control" />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="input1" className="form-label">Phone</label>
                                                                <input type={'phone'} value={openAdd.phone} onChange={(e) => { setAddModal(data => ({ ...data, phone: e.target.value })) }} className="form-control" />
                                                            </div>
                                                            <div className="col-md-12 mt-3">
                                                                <label htmlFor="input1" className="form-label">Role</label>
                                                                <Select options={[{ value: 'admin', label: 'admin' }, { value: 'sub-admin', label: 'sub-admin' }]}
                                                                    // value={openAdd.role}
                                                                    onChange={(array) => {
                                                                        setAddModal(data => ({ ...data, role: array.value }))
                                                                    }}
                                                                // options={colourOptions}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <label htmlFor="inputChoosePassword" className="form-label">Password</label>
                                                                <div className="input-group" id="show_hide_password">
                                                                    <input type={show ? "text" : "password"} className="form-control border-end-0" onChange={(e) => { setAddModal(data => ({ ...data, password: e.target.value })) }} id="inputChoosePassword" value={openAdd.password} placeholder="Enter Password" /> <a href="javascript:;" onClick={() => setshow(e => !e)} className="input-group-text bg-transparent" ><i className={show ? "bx bx-show" : "bx bx-hide"} /></a>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-sm-9 text-secondary">
                                                                    <Button className="btn btn-primary" onClick={handleAdd} text="Save Changes" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ReactModal>

                    </div>
                </div>
            </div >
        </div >

    )
}

export default Role_Management