import React from 'react';
import Button from './Button';
import { useDispatch } from 'react-redux';
import { DeleteCourseLesson } from '../reducer/Auth';


const AddVideos = ({ craeteNew, setCreateNew, index, onChange, item, handleVideoThumbnail, isEdit = false }) => {
    const dispatch = useDispatch()

    console.log("ITEM vide",craeteNew._id);
    console.log("ITEM vide",item._id);


    if (item?._id) {
        return (<div className="col mt-3">
            <h5 class="card-title">{index + 1}.Lesson Details</h5>
            <div className="card radius-10 bg-gradient-ibiza">
                <div className="card-body">
                    <div className="d-flex align-items-center card_box">
                        <div className="me-auto">
                            <p className="mb-0 text-white">{item?.title}</p>
                            <h4 className="my-1 text-white"></h4>
                            <p className="mb-0 font-13 text-white">{item?.description} </p>
                            <div class="fileUpload mt-3">
                                {/* <Button   onClick={() => {
                                    let VIDEO = craeteNew?.videos
                                    delete VIDEO[index]
                                    console.log(VIDEO)
                                    // setCreateNew((item) => ({ ...item, videos: VIDEO }))
                                }} text="Delete" /> */}
                                <span
                                    onClick={() => {
                                        let VIDEO = [...craeteNew?.videos]
                                        delete VIDEO[index]
                                        VIDEO = VIDEO?.filter((e) => e)
                                        setCreateNew((item) => ({ ...item, videos: VIDEO }))

                                        dispatch(DeleteCourseLesson(`${craeteNew._id}/${item._id}`))

                                    }}
                                >Delete</span>
                            </div>
                        </div>
                        <div>
                            <img src={item?.thumbnail} style={{ filter: 'none', margin: 0 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }


    return (
        <div class="card mt-3">

            <div class="col mt-3 row">
                {/* <div class="col-md-6 col-xl-4 col-lg-4">
                    <div class="fileUpload">
                        <input type="file" class="upload" />
                        <span>Upload</span>
                    </div>
                </div> */}
                {/* <div class="col-md-6 col-xl-8 col-lg-8"> */}

                <div class="col-md-12">
                    <div>
                        <div class="card-body">
                            <h5 class="card-title">{index + 1}.Lesson Details</h5>
                            <form>
                                <div className="col-md-12">
                                    <label htmlFor="input1" className="form-label">Title</label>
                                    <input type={'text'} name={'over_view'} className="form-control" onChange={(e) => {
                                        let VIDEO = craeteNew?.videos
                                        VIDEO[index] = { ...VIDEO[index], title: e.target.value }
                                        setCreateNew((item) => ({ ...item, videos: VIDEO }))
                                    }} />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="input1" className="form-label">Description</label>
                                    <textarea class="form-control" name={'description'} onChange={(e) => {
                                        let VIDEO = craeteNew?.videos
                                        VIDEO[index] = { ...VIDEO[index], title: e.target.value }
                                        setCreateNew((item) => ({ ...item, videos: VIDEO }))
                                    }} />
                                </div>
                                <div className="col-md-12 mt-3 ">
                                    <div class="fileUpload">
                                        <input type="file" accept="video/mp4,video/x-m4v,video/*" class="upload" onChange={(e) => onChange(e, index)} />
                                        {item?.file ? <span>{item?.file?.name}</span> : <span>Upload a Video</span>}
                                    </div>
                                    <div class="fileUpload mt-3">
                                        <input type="file" accept="image/*" class="upload" onChange={(e) => handleVideoThumbnail(e, index)} />
                                        {item?.thumbnail ? <span>{item?.thumbnail?.name}</span> : <span>Upload a Video Thumbnail</span>}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddVideos







// <div class="col mt-3 row">
//     <div class="col-md-6 col-xl-4 col-lg-4">
//         <div class="upload-box">
//             <p class="upload-text">Drag and drop video here or</p>
//             <label for="video" class="upload-button">Browse{ }</label>
//             <input type="file" onChange={(e) => onChange(e, index)} id="video"  name="video" />
//         </div>
//     </div>
//     <div class="col-md-6 col-xl-8 col-lg-8">
//         <div>
//             <div class="card-body">
//                 <form>
//                     <div className="col-md-12 ">
//                         <label htmlFor="input1" className="form-label">Title</label>
//                         <input type={'text'} name={'over_view'} className="form-control" onChange={(e) => {
//                             let VIDEO = craeteNew?.videos
//                             VIDEO[index] = { ...VIDEO[index], title: e.target.value }
//                             setCreateNew((item) => ({ ...item, videos: VIDEO }))
//                         }} />
//                     </div>
//                     <div className="col-md-12 mt-3">
//                         <label htmlFor="input1" className="form-label">Description</label>
//                         <textarea class="form-control" name={'description'} onChange={(e) => {
//                             let VIDEO = craeteNew?.videos
//                             VIDEO[index] = { ...VIDEO[index], title: e.target.value }
//                             setCreateNew((item) => ({ ...item, videos: VIDEO }))
//                         }} />
//                     </div>
//                 </form>
//             </div>
//         </div>
//     </div>
// </div>

