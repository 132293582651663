import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';
import { useSelector } from 'react-redux';

const Ecommerce_Management = () => {
    const GetButtons = (id) => {
        return ReactDomServer.renderToString(<Modal_Actions view id={id} />)
    }
    const { ProductList } = useSelector((State) => State.Auth)
    // let table_data = {
    //     data: [
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(1)],
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(2)],
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(3)],
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(4)],
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(5)],
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(6)],
    //         ['Cheek &amp; Eye Blush ', "£10.00 ", 'Cheek &amp; Eye Blush (1)', 'pink', 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', GetButtons(7)],
    //     ],
    //     columns: [

    //         { title: 'Actions' }
    //     ],
    // }

    const [LIST, setLIST] = useState({})

    // available
    // formatted_price 
    // inventory_quantity 
    // option_values
    // [{option_id: 10328001347820, name: "Title", value: "Default Title"}] 
    // price 



    useEffect(() => {
        if (ProductList) {

            let table_data = {
                data: [],
                columns: [
                    { title: 'Image' },
                    { title: 'Name' },
                    { title: 'Price' },
                    { title: 'Category' },
                    { title: 'Category Id' },
                    { title: 'Product Id' },
                ],
            }
            ProductList?.forEach(element => {
                const {
                    title,
                    product_id,
                    images,
                    Category,
                    CategoryId,
                    ProductId,
                } = element
                table_data.data.push([
                    `<img src="${images[0]?.src}" alt class="product-C-img" />` || '- - -',
                    title || '- - -',
                    Category,
                    CategoryId,
                    ProductId,
                    product_id || '- - -',
                    // body_html,
                ])
            });
            setLIST(table_data)

        }
    }, [ProductList])
    const modal_fields = [
        {
            type: 'text',
            name: 'Name',
            label: 'Name Pic',
        },
        {
            type: 'text',
            name: 'Price',
            label: 'Price',
        },
        {
            type: 'text',
            name: 'Category',
            label: 'Category',
        },
        {
            type: 'text',
            name: 'Color',
            label: 'Color',
        },
        {
            type: 'text',
            name: 'Description',
            label: 'Description',
        }
    ]
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">E-commerce Management</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">E-commerce Management</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            {LIST?.data?.length ? <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                <div className="table-responsive">
                                    <Table {...LIST} />
                                    <Modal data={LIST.data} field={modal_fields} SubmitAPI="/api-url" />
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Ecommerce_Management