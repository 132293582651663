import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReactModal from '../components/ReactModal';
import { DeleteSubscription, DeleteSubscriptionData } from '../reducer/Auth';


const Membership_Management = () => {
    const GetButtons = (element,id) => {
        return ReactDomServer.renderToString(
        <ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
            <Modal_Actions view id={id} />
            </li>
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>
        )
    }
    const [LIST, setLIST] = useState({})

    const { UserList, Course, Subscription } = useSelector((State) => State.Auth)
    const [deleteMemberShip, setDeleteMemberShip] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (Subscription) {
            // let CourseData = [...Course?.Course, ...Course?.newCourse]
            let CC_OBJ = {}
            // CourseData?.map((item) => {
            //     CC_OBJ[item?.plan_id] = item
            // })
            let table_data = {
                data: [],
                columns: [
                    { title: 'User Email' },
                    { title: 'Buy' },
                    { title: 'Points' },
                    { title: 'Payment' },
                    { title: 'Current Subscription' },
                    { title: 'Actions' }
                ],
            }
            Subscription?.forEach((element, index) => {
                table_data.data.push([
                    element?.metadata?.customerEmail || '- - -',
                    moment(element.current_period_start * 1000).format('LL') || '- - -',
                    element.items.data[0]?.plan?.amount || '- - -',
                    `£${element.items.data[0]?.plan?.amount}` || '- - -',
                    CC_OBJ[element.items.data[0]?.plan?.id]?.title || '- - -',
                    GetButtons(element,index)
                ])
            });
            setLIST(table_data)
        }
    }, [Subscription])





    const modal_fields = [
        {
            type: 'text',
            name: 'User Email',
            label: 'User Email',
        },
        {
            type: 'text',
            name: 'Buy',
            label: 'Buy',
        },
        {
            type: 'text',
            name: 'Points',
            label: 'Points',
        },
        {
            type: 'text',
            name: 'Payment',
            label: 'Payment',
        },
        {
            type: 'text',
            name: 'Current Subscription',
            label: 'Current Subscription',
        }
    ]
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Membership Management</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Membership Management</li>
                            </ol>
                        </nav>
                    </div>
                    {/* <div className="ms-auto">
                        <Modal data={table_data.data} create field={modal_fields} SubmitAPI="/api-url" />
                    </div> */}
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            {LIST?.data?.length ? <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                <div className="table-responsive">
                                    <Table {...LIST}  callback={(e, type) => {
                                        setDeleteMemberShip(e)
                                    }} />
                                    <Modal data={LIST.data} field={modal_fields} SubmitAPI="/api-url" />
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
                
            </div>
            <ReactModal open={deleteMemberShip} title='Confirmation Alert!' onClose={() => setDeleteMemberShip(false)} >
                    <div className="modal-body">
                        <div className="create_noti_form">
                            <h5>Do you want to delete {deleteMemberShip?.metadata?.customerEmail} subscription?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setDeleteMemberShip(false)} data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-danger" onClick={() => { dispatch(DeleteSubscriptionData(deleteMemberShip?.id +`/${deleteMemberShip?.customer}`)); setDeleteMemberShip(false) }} data-bs-dismiss="modal">Yes</button>
                    </div>
                </ReactModal>
        </div>
        

    )
}

export default Membership_Management