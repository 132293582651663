import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MessageShow, Validator, showResponseError } from '../Utils/Constant';
import { POST } from '../Utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData, setUserToken } from '../reducer/Auth';
const Login_Page = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [show, setshow] = useState(false)
    const dispatch = useDispatch()
    const { User } = useSelector((STATE) => STATE.Auth)



    useEffect(() => {
        if (User?._id) {
            navigate('/');
        }
    }, [User])
    const SubmitLogin = async (e) => {
        e.preventDefault()
        const params = { email, password }
        if (Validator(params)) {
            try {
                let response = await POST('/admin/login', params)
                // localStorage.setItem('login_data', JSON.stringify(response.data?.user))
                // localStorage.setItem('token', JSON.stringify(response.data?.access_token))
                dispatch(setUserData(response.data?.user))
                dispatch(setUserToken(response.data?.access_token))
                MessageShow('success', response.message)
            } catch (error) {
                MessageShow('error', showResponseError(error))

            }
        }
    }
    // console.log(email)
    return (
        <div className="wrapper">
            <div className="section-authentication-cover">
                <div className>
                    <div className="row g-0">
                        <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
                            <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0">
                                <div className="card-body">
                                    <img src="assets/images/login-images/login-cover.png" className="img-fluid auth-img-cover-login" width={650} alt />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center">
                            <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0">
                                <div className="card-body p-sm-5">
                                    <div className>
                                        <div className="mb-3 text-center">
                                            <img src="assets/images/logo-light.png" className="w-50" alt />
                                        </div>
                                        <div className="text-center mb-4">
                                            <h5 className>Trigwell cosmetics</h5>
                                            <p className="mb-0">Please log in to your account</p>
                                        </div>
                                        <div className="form-body">
                                            <form className="row g-3" id="jQueryValidationForm" method="post3">
                                                <div className="col-12">
                                                    <label htmlFor="inputEmailAddress" className="form-label">Email</label>
                                                    <input type="email" name="email" className="form-control" id="inputEmailAddress" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="jhon@example.com" />
                                                </div>
                                                <div className="col-12">
                                                    <label htmlFor="inputChoosePassword" className="form-label">Password</label>
                                                    <div className="input-group" id="show_hide_password">
                                                        <input type={show ? "text" : "password"} className="form-control border-end-0" onChange={(e) => setPassword(e.target.value)} id="inputChoosePassword" value={password} placeholder="Enter Password" /> <a href="javascript:;" onClick={() => setshow(e => !e)} className="input-group-text bg-transparent"><i className={show ? "bx bx-show" : "bx bx-hide"} /></a>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remember Me</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-end"> <a href="auth-cover-forgot-password.html">Forgot Password ?</a>
                                                </div> */}
                                                <div className="col-12">
                                                    <div className="d-grid">
                                                        <button type="submit" onClick={SubmitLogin} className="btn btn-primary">Sign in</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </div>
            </div>
        </div>

    )
}

export default Login_Page