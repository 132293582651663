import React from 'react'
import $ from 'jquery';
import { useEffect, useRef } from "react";
import "datatables.net-dt/css/jquery.dataTables.min.css";


const Table = ({ columns, actions, data, callback = () => { }, T_name = 'table1' }) => {
    // console.log(data[0])
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    // console.log(tableRef)
    const tableName = T_name;
    useEffect(() => {
        const table = $(`#${tableName}`).DataTable(
            {
                data: data,
                columns: columns,
                destroy: true,  // I think some clean up is happening here
                searching: true,
                // render: render
            }
        )
        $(tableRef.current).on('click', 'a', function () {
            const name = $(this).data('name');
            const data = $(this).data('data');
            callback(data, name)
        });
        // Extra step to do extra clean-up.
        return function () {
            // console.log("Table destroyed")
            table.destroy()
        }
    }, [data])

    return (
        <table className="table table-striped table-bordered" width="100%" id={tableName} ref={tableRef}></table>
    )
}

export default Table