import React from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';

const Reviews_Management = () => {
    const GetButtons = (id) => {
        return ReactDomServer.renderToString(<Modal_Actions view delete id={id} />)
    }
    let table_data = {
        data: [
            ['Cheek & Eye Blush', "Cheek & Eye Blush (1)", '<i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i>', 'Lorem ipsum dolor sit amet,', GetButtons(0)],
            ['Cheek & Eye Blush', "Cheek & Eye Blush (1)", '<i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i>', 'Lorem ipsum dolor sit amet,', GetButtons(1)],
            ['Cheek & Eye Blush', "Cheek & Eye Blush (1)", '<i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i>', 'Lorem ipsum dolor sit amet,', GetButtons(2)],
            ['Cheek & Eye Blush', "Cheek & Eye Blush (1)", '<i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i>', 'Lorem ipsum dolor sit amet,', GetButtons(3)],
            ['Cheek & Eye Blush', "Cheek & Eye Blush (1)", '<i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i><i class="bx bx-star"></i>', 'Lorem ipsum dolor sit amet,', GetButtons(4)],

        ],
        columns: [
            { title: 'Product Name' },
            { title: 'Category' },
            { title: 'Star Rating' },
            { title: 'Description' },
            { title: 'Actions' }
        ],
    }
    const modal_fields = [
        {
            type: 'text',
            name: 'Product Name',
            label: 'Product Name',
        },
        {
            type: 'text',
            name: 'Category',
            label: 'Category',
        },
        {
            type: 'text',
            name: 'Star Rating',
            label: 'Star Rating',
        },
        {
            type: 'text',
            name: 'Description',
            label: 'Description',
        }
    ]
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Reviews Management</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Reviews Management</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                <div className="table-responsive">
                                    <Table {...table_data} />
                                    <Modal data={table_data.data} field={modal_fields} SubmitAPI="/api-url" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Reviews_Management