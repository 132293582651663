import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePassword, UpdateProfile, UpdateProfileImage } from '../reducer/Auth'
import { MessageShow } from '../Utils/Constant'

const User_Profile = () => {

  const { User } = useSelector((State) => State.Auth)
  const [LocalUser, setLocalUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profile: '',
    phone: '',
    profile: null
  })


  useEffect(() => {
    if (User) {
      setLocalUser(User)
    }
  }, [User])
  const dispatch = useDispatch()

  const Profile = (event) => {
    var reader = new FileReader();
    reader.onload = async function () {
      let isMediaAdd = event.target.files[0]
      let file = {
        uri: reader.result,
        name: isMediaAdd.name,
        type: isMediaAdd.type, // it may be necessary in Android.
      }
      setLocalUser((add) => ({ ...add, profile: file?.uri }))
      let formdata = new FormData()
      formdata.append(`profile`, isMediaAdd)
      dispatch(UpdateProfileImage(formdata))
    };
    reader.readAsDataURL(event.target.files[0]);

  };

  const [CurrentPassword, setCurrentPassword] = useState('')
  const [NewPassword, setNewPassword] = useState('')
  const [RetypePassword, setRetypePassword] = useState('')


  const [CurrentShowPassword, setCurrentShowPassword] = useState(false)
  const [NewShowPassword, setNewShowPassword] = useState(false)
  const [RetypeShowPassword, setRetypeShowPassword] = useState(false)


  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!CurrentPassword || !NewPassword || !RetypePassword) {
      return MessageShow('error', 'All fileds are required')
      //  msg: 'All fileds are required'
    }
    if (NewPassword !== RetypePassword) {
      return MessageShow('error', "New password and Retype password doesn't match")
      //  msg: "New password and Retype password doesn't match"
    }
    if (NewPassword.length < 8) {
      return MessageShow('error', "New password must be 8 characters or above")
      //  msg: "New password must be 8 characters or above"
    }

    dispatch(ChangePassword({ currentPassword: CurrentPassword, changePassword: NewPassword }))
    // currentPassword, changePassword


  }







  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">User Profile</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item"><a href="javascript:;"><i className="bx bx-home-alt" /></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">User Profile</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container">
          <div className="main-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      {LocalUser?.profile ? <img src={LocalUser?.profile} alt="Admin" className="rounded-circle p-1 bg-primary" width={110} /> : null}
                      <div className="mt-3">
                        {/* <h4>John Doe</h4> */}
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <div className="upload_profile position-relative">
                            <input onChange={Profile} accept="image/*" type="file" className="form-control position-absolute opacity-0" />
                            <Button className="btn btn-primary" text="Upload Photo" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-3">
                        <h6 className="mb-0">First Name</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input type="text" value={LocalUser?.firstName} onChange={(e) => { setLocalUser((data) => ({ ...data, firstName: e.target.value })) }} className="form-control" defaultValue="John" />
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Last Name</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input type="text" value={LocalUser?.lastName} onChange={(e) => { setLocalUser((data) => ({ ...data, lastName: e.target.value })) }} className="form-control" defaultValue="John Doe" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Email</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input type="text" value={LocalUser?.email} onChange={(e) => { setLocalUser((data) => ({ ...data, email: e.target.value })) }} disabled className="form-control" defaultValue="John_Doe@gamil.com" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Phone</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input type="text" value={LocalUser?.phone} onChange={(e) => { setLocalUser((data) => ({ ...data, phone: e.target.value })) }} className="form-control" defaultValue="(123)-456-7890" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3" />
                      <div className="col-sm-9 text-secondary">
                        <Button className="btn btn-primary px-4"
                          onClick={() => {
                            dispatch(UpdateProfile({ ...LocalUser }))
                          }}

                          text="Save Changes" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form id="jQueryValidationForm" method="post3" action>
                      <div className="row mb-3 align-items-center">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Current Password</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          <div className="input-group" id="show_hide_password">
                            <input
                              type={CurrentShowPassword ? "text" : "password"}
                              className="form-control border-end-0"
                              onChange={(e) => { setCurrentPassword(e.target.value) }}
                              id="inputChoosePassword"
                              // value={openAdd.password}
                              placeholder="Enter Current Password" />
                            <a href="javascript:;"
                              onClick={() => setCurrentShowPassword(e => !e)}
                              className="input-group-text bg-transparent" >
                              <i className={CurrentShowPassword ? "bx bx-show" : "bx bx-hide"} />
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3 align-items-center">
                        <div className="col-sm-3">
                          <h6 className="mb-0">New Password</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          <div className="input-group" id="show_hide_password">
                            <input
                              type={NewShowPassword ? "text" : "password"}
                              className="form-control border-end-0"
                              onChange={(e) => { setNewPassword(e.target.value) }}
                              id="inputChoosePassword"
                              // value={openAdd.password}
                              placeholder="Enter  New Password" />
                            <a href="javascript:;"
                              onClick={() => setNewShowPassword(e => !e)}
                              className="input-group-text bg-transparent" >
                              <i className={NewShowPassword ? "bx bx-show" : "bx bx-hide"} />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3 align-items-center">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Retype Password</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          <div className="input-group" id="show_hide_password">
                            <input
                              type={RetypeShowPassword ? "text" : "password"}
                              className="form-control border-end-0"
                              onChange={(e) => { setRetypePassword(e.target.value) }}
                              id="inputChoosePassword"
                              // value={openAdd.password}
                              placeholder="Enter Password" />
                            <a href="javascript:;"
                              onClick={() => setRetypeShowPassword(e => !e)}
                              className="input-group-text bg-transparent" >
                              <i className={RetypeShowPassword ? "bx bx-show" : "bx bx-hide"} />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-9 text-secondary">
                          <Button className="btn btn-primary px-4" onClick={handleResetPassword} text="Save Changes" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default User_Profile