import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import Button from './Button'
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });


const Modal = ({ data, field, SubmitAPI, create, callback = () => { } }) => {
    const [modal_type, SetModalType] = useState(null);
    useEffect(() => {
        $('[data-id]').click(function (e) {
            e.preventDefault();
            SetModalType({
                type: $(this).attr('data-type'),
                data_id: $(this).attr('data-id')
            })
        })
    })
    const SubmitForm = (e) => {
        e.preventDefault();
        callback(data, modal_type)
    }
    if (create) {
        return (<>
            <Button text="Create" onClick={() => {
                SetModalType({
                    type: 'create'
                })
            }} className="d-flex align-items-center btn btn-primary font-18" icon={<i className="bx bx-plus ms-1" />} />
            {modal_type && modal_type.type === 'create' ?
                <div className="modal fade show" id="edit_event_modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{modal_type.type}</h5>
                                <button type="button" className="btn-close" onClick={() => { SetModalType(null) }} data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="profile_modal_wrap">
                                    <div className="main-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form id="style-3">
                                                            {
                                                                field.map((fild) => {
                                                                    return <div className="col-md-12">
                                                                        <label htmlFor="input1" className="form-label">{fild.label}</label>
                                                                        <input type={fild.type} name={fild.name} id className="form-control" />
                                                                    </div>
                                                                })
                                                            }

                                                            <div className="row mt-3">
                                                                <div className="col-sm-9 text-secondary">
                                                                    <Button className="btn btn-primary" onClick={SubmitForm} text="Save Changes" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : false}
        </>)
    }
    if (modal_type === null) {
        return false
    }
    if (modal_type.type === 'edit' || modal_type.type === 'create') {
        if (field === undefined || field === null) {
            console.error('Fields are not defined')
            return false
        }
    }
    if (modal_type.type === 'view' || modal_type.type === 'edit') {
        if (data === undefined || data === null) {
            console.error('Data are not defined')
            return false
        }
    }

    return (
        <>
            {modal_type.type === 'view' ?
                <div className="modal fade show" id="edit_event_modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{modal_type.type}</h5>
                                <button type="button" className="btn-close" onClick={() => { SetModalType(null) }} data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="profile_modal_wrap">
                                    <div className="main-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form id="style-3">
                                                            {
                                                                field.map((fild, key) => {
                                                                    return <div className="row">
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="input1" className="form-label">{fild.label}</label>
                                                                        </div>
                                                                        <div className='col-md-8'>
                                                                            {renderHTML(data[modal_type.data_id][key])}
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : false}

            {modal_type.type === 'edit' ?
                <div className="modal fade show" id="edit_event_modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{modal_type.type}</h5>
                                <button type="button" className="btn-close" onClick={() => { SetModalType(null) }} data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="profile_modal_wrap">
                                    <div className="main-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form id="style-3">
                                                            {
                                                                field.map((fild, key) => {
                                                                    if (fild.type === 'file') {
                                                                        return <div className="col-md-12">
                                                                            {renderHTML(data[modal_type.data_id][key])}
                                                                            <label htmlFor="input1" className="form-label">{fild.label}</label>
                                                                            <input type={fild.type} name={fild.name} id className="form-control" />
                                                                        </div>
                                                                    }
                                                                    return <div className="col-md-12">
                                                                        <label htmlFor="input1" className="form-label">{fild.label}</label>
                                                                        <input type={fild.type} name={fild.name} defaultValue={data[modal_type.data_id][key]} id className="form-control" />
                                                                    </div>
                                                                })
                                                            }

                                                            <div className="row mt-3">
                                                                <div className="col-sm-9 text-secondary">
                                                                    <Button className="btn btn-primary" onClick={SubmitForm} text="Save Changes" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : false}
            {modal_type.type === 'delete' ? <div className="modal fade show" id="edit_event_modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation Alert!</h5>
                            <button type="button" className="btn-close" onClick={() => { SetModalType(null) }} data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="create_noti_form">
                                <h5>Do you want to delete this content ?</h5>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div> : false}
        </>
    )
}

export default Modal