import { combineReducers, configureStore } from '@reduxjs/toolkit'
import AuthReducer from './Auth'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
    Auth: AuthReducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["Auth"],
};

const _persistReducer = persistReducer(persistConfig, reducers);

// export const store = configureStore({
//     reducer: {
//         Auth: AuthReducer
//     },
// })
export const store = configureStore({
    reducer: _persistReducer,
    // middleware: [thunk],
    // devTools: process.env.NODE_ENV !== 'production',
});