import $ from 'jquery'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Scripts = () => {
    const navigate = useNavigate();
    const { User } = useSelector((STATE) => STATE.Auth)
    useEffect(() => {
        if (!User?._id) {
            navigate('/auth-cover-signin');
        }
    }, [])


    $('#scripts').html("");
    const scrpt = ['assets/js/bootstrap.bundle.min.js', 'assets/js/jquery.min.js', 'assets/plugins/simplebar/js/simplebar.min.js', 'assets/plugins/metismenu/js/metisMenu.min.js', 'assets/plugins/vectormap/jquery-jvectormap-2.0.2.min.js', 'assets/plugins/vectormap/jquery-jvectormap-world-mill-en.js', 'assets/plugins/sparkline-charts/jquery.sparkline.min.js'];

    for (let i = 0; i < scrpt.length; i++) {
        const src = scrpt[i];
        let script = document.createElement('script');
        script.src = src;
        document.getElementById("scripts").appendChild(script);
    }
}

export default Scripts