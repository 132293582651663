import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from '../components/ReactModal';
import Button from '../components/Button';
import Select from 'react-select'
import { GetReels, UpdateReels } from '../reducer/Auth';


const Social_Media_Reels = () => {
    const GetButtons = (element) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="edit" >
                    <i className="bx bx-edit"></i>
                </a>
            </li>
        </ul>)
    }
    const { Reels } = useSelector((State) => State.Auth)
    // let table_data = {
    //     data: [
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(0)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(1)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(2)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(3)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(4)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(5)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(6)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(7)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(8)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(9)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(10)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(11)],
    //         ['Get Ready With Me ',"Lorem, ipsum dolor sit amet consectetur adipisicing elit.", 'product', GetButtons(12)],
    //     ],
    //     columns: [
    //         {title:'Reel Name'}, 
    //         {title:'Description'}, 
    //         {title:'Product list'},
    //         {title:'Actions'}
    //     ],
    // }
    const [LIST, setLIST] = useState({})
    const [open, setOpen] = useState(false)
    const { ProductList } = useSelector((State) => State.Auth)





    useEffect(() => {
        if (Reels) {

            let table_data = {
                data: [],
                columns: [
                    { title: 'Image' },
                    { title: 'Reel Name' },
                    { title: 'Reel link' },
                    { title: 'Duration' },
                    { title: 'Products' },
                    // { title: 'Links' },
                    { title: 'Actions' }
                ],
            }
            function breakString(inputString, spacesPerLine) {
                // Use regular expression to find every 10 spaces
                const regex = new RegExp(`(([^ ]* ){${spacesPerLine}}[^ ]*)`, 'g');

                // Replace matches with the matched substring followed by a newline
                const resultString = inputString.replace(regex, '$1<br>');

                return resultString;
            }


            Reels?.forEach((element, index) => {
                const {
                    image,
                    caption,
                    products,
                    video,
                    video_duration,
                    links
                } = element
                table_data.data.push([
                    `<a href="${image}" target="_blank">View</a>`,
                    breakString(caption, 6) || '- - -',
                    `<a href="${video}" target="_blank">View</a>` || '- - -',
                    Math.round(video_duration) + ' sec' || '- - -',
                    products?.map((item) => item?.value).join('</br>') || '- - -',
                    // links || '- - -',
                    GetButtons(element)
                ])
            });
            setLIST(table_data)

        }
    }, [Reels])

    const modal_fields = [
        {
            type: 'text',
            name: 'Products',
            label: 'Products',
        },
        {
            type: 'text',
            name: 'Links',
            label: 'Links',
        }
    ]
    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ]

    const [options, setOptions] = useState([])


    useEffect(() => {
        if (ProductList) {
            let LIST = []
            ProductList?.forEach(PRODUCT => {
                LIST.push({ value: `${PRODUCT.title} ,#ID:${PRODUCT?.product_id}`, label: `${PRODUCT.title} ,#ID:${PRODUCT?.product_id}` })
                // PRODUCT?.variants?.forEach((variant) => {
                //     LIST.push({ value: `${PRODUCT.title} type:${variant?.title},#V_ID:${variant?.id}, #P_ID:${PRODUCT?.product_id}`, label: `${PRODUCT.title} type:${variant?.title}, #V_ID:${variant?.id},#P_ID:${PRODUCT?.product_id}` })
                // })
            });
            setOptions(LIST)
        }
    }, [ProductList])



    const [Selected, setSelected] = useState([])



    const dispatch = useDispatch()
    const handlePostUpdate = () => {
        dispatch(UpdateReels({ products: Selected, id: open?._id }))
        setSelected([])
        setOpen(false)
    }





    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Social Media Reels Management</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Social Media Reels Management</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                    <div className="btn-group" onClick={()=>dispatch(GetReels())}> 
                             <Button text="Refresh" className="d-flex align-items-center btn btn-primary font-16" icon={<i className="bx bx-refresh ms-1" />} />
                        </div>
                    </div>
                </div> 
                <hr />
                <div className="card">
                    <div className="card-body">
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data?.length ? <div className="table-responsive">
                                    <Table {...LIST} callback={(data, name) => {
                                        setOpen(data)
                                        setSelected(data.products)
                                    }} />
                                    <Modal data={LIST.data} field={modal_fields} SubmitAPI="/api-url" />
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal open={open} onClose={() => setOpen(false)} >
                    <div class="modal-body">
                        <div className="col-md-12">
                            <label htmlFor="input1" className="form-label">Products</label>
                            <Select isMulti options={options}
                                value={Selected}
                                // isMulti
                                // styles={styles}
                                // isClearable={value.some((v) => !v.isFixed)}
                                // name="colors"
                                // className="basic-multi-select"
                                // classNamePrefix="select"
                                onChange={(array) => { setSelected(array) }}
                            // options={colourOptions}

                            />
                            {/* <input type={fild.type} name={fild.name} id className="form-control" /> */}
                        </div>
                        {/* <div className="col-md-12">
                            <label htmlFor="input1" className="form-label">Products</label>
                            <Select isMulti options={options} />
                            <input type={fild.type} name={fild.name} id className="form-control" />
                        </div> */}
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={() => handlePostUpdate('Suprice')} text="Save Changes" />
                    </div>
                </ReactModal>
            </div>
        </div>

    )
}

export default Social_Media_Reels