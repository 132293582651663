import React, { useEffect } from 'react'
import Chart from 'chart.js/auto';

const Chart_Canvas = (props) => {
  useEffect(() => {
    setTimeout(() => {
      var ctx = document.getElementById(`chart${props?.chart_data?.chartID}`).getContext('2d');
      var myLineChart = new Chart(ctx, props?.chart_data);
    
    }, 100);

  }, [props])
  return (
    <canvas id={`chart${props?.chart_data?.chartID}`} width={100} height={45} />
  )
}

export default Chart_Canvas