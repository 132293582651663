import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useSelector } from 'react-redux';

const Sidebar = () => {
    // if (localStorage.getItem('login_data') === null) {
    //     window.location.replace('/auth-cover-signin')
    // }
    const { User } = useSelector((STATE) => STATE.Auth)
    useEffect(() => {
        if (!User?._id) {
            window.location.replace('/auth-cover-signin')
        }
    }, [])

    useEffect(() => {
        $(".metismenu>li>a").click(function () {
            $(".metismenu li.mm-active").removeClass("mm-active");
            $(this).parent().addClass("mm-active");
        });
    })


    const Logout = () => {
        localStorage.removeItem('persist:root');
        window.location.replace('/auth-cover-signin')
    }

    return (
        <div className="sidebar-wrapper" data-simplebar="true">
            <div className="sidebar-header">
                <div>
                    <img src="assets/images/logo.png" className="logo-icon logo_dark" alt="logo icon" />
                    <img src="assets/images/logo.png" className="logo-icon logo_light" alt="logo icon" />
                </div>
                <div className="toggle-icon ms-auto"><i className="bx bx-menu" />
                </div>
            </div>
            <ul className="metismenu" id="menu" >
                <li className="mm-active">
                    <Link to="/">
                        <div className="parent-icon"><i className="bx bx-home" />
                        </div>
                        <div className="menu-title">Dashboard</div>
                    </Link>
                </li>
                <li>
                    <Link to="/user-management">
                        <div className="parent-icon"><i className="bx bx-user-circle" />
                        </div>
                        <div className="menu-title">Users Management</div>
                    </Link>
                </li>
                <li>
                    <Link to="/content-management">
                        <div className="parent-icon"><i className="bx bx-book-content" />
                        </div>
                        <div className="menu-title">Content Management</div>
                    </Link>
                </li>
                <li>
                    <Link to="/e-commerce-management">
                        <div className="parent-icon"><i className="bx bx-cart" />
                        </div>
                        <div className="menu-title">E-commerce Management</div>
                    </Link>
                </li>
                <li>
                    <Link to="/social-media-reels">
                        <div className="parent-icon"><i className="bx bx-movie-play" />
                        </div>
                        <div className="menu-title">Social Media Reels Management</div>
                    </Link>
                </li>
                <li>
                    <Link to="/product-tutorial">
                        <div className="parent-icon"><i className="bx bx-video" />
                        </div>
                        <div className="menu-title">Product Tutorial</div>
                    </Link>
                </li>
                <li>
                    <Link to="/subscription-features">
                        <div className="parent-icon"><i className="bx bxs-file-blank" />
                        </div>
                        <div className="menu-title">Subscription Feature</div>
                    </Link>
                </li>
                <li>
                    <Link to="/monthly-subscription-limit">
                        <div className="parent-icon"><i className="bx bx-file" />
                        </div>
                        <div className="menu-title">Monthly Subscription Limit</div>
                    </Link>
                </li>
                <li>
                    <Link to="/membership-management">
                        <div className="parent-icon"><i className="lni lni-ticket-alt" />
                        </div>
                        <div className="menu-title">Subscriptions</div>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/reviews-management">
                        <div className="parent-icon"><i className="bx bx-star" />
                        </div>
                        <div className="menu-title">Reviews Management</div>
                    </Link>
                </li> */}
                {User?.userType === 'admin' ? <li>
                    <Link to="/role-management">
                        <div className="parent-icon"><i className="bx bx-user" />
                        </div>
                        <div className="menu-title">Roles Management</div>
                    </Link>
                </li> : null}
                {/* <li>
                    <Link to="/support-ticket">
                        <div className="parent-icon"><i className="bx bx-support" />
                        </div>
                        <div className="menu-title">Support Tickets</div>
                    </Link>
                </li>
                <li>
                    <Link to="/static-content">
                        <div className="parent-icon"><i className="bx bx-book-content" />
                        </div>
                        <div className="menu-title">Static Content</div>
                    </Link>
                </li> */}
                <li>
                    <Link to="/admin-notifications">
                        <div className="parent-icon"><i className="bx bxs-bell" />
                        </div>
                        <div className="menu-title">Push Notifications</div>
                    </Link>
                </li>
                <li>
                    <Link to="/user-profile">
                        <div className="parent-icon"><i className="bx bx-user" />
                        </div>
                        <div className="menu-title">Profile</div>
                    </Link>
                </li>
                <li onClick={Logout}>
                        <Link to="" className="d-flex align-content-center">
                            <div className="parent-icon"><i className="bx bx-log-out" />
                            </div>
                            <div className="menu-title">Logout</div>
                        </Link>
                    </li>
            </ul>
            {/* <div className="sidebar-logout">
                <ul>
                    <li onClick={Logout}>
                        <Link to="" className="d-flex align-content-center">
                            <div className="parent-icon"><i className="bx bx-log-out" />
                            </div>
                            <div className="menu-title">Logout</div>
                        </Link>
                    </li>
                </ul>
            </div> */}
        </div>

    )
}

export default Sidebar
