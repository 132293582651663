import React, { useEffect, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import Button from '../components/Button';
import Modal_Actions from '../components/Modal_Actions';
import ReactModal from '../components/ReactModal';
import Table from '../components/Table';
import AddVideos from '../components/AddVideos';
import { MessageShow, Validator } from '../Utils/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { AddCategry, AddCourse, AddTutorial, DeleteCourse, DeleteTutorial, EditCourse, GetDeleteCate,EditTutorial, AddSubscription,DeleteSubscription, EditSubscription, EditSubscriptionTitle } from '../reducer/Auth';
import Modal from '../components/Modal';
import AddCate from '../components/AddCate';
import Select from 'react-select'
import { POST } from '../Utils/API';
import { API_URLS } from '../Utils/EndPoints';
import { GET } from '../Utils/API';

const SubscriptionFeature = () => {
    const [open, setOpen] = useState(false)
    const [editOpen,setEditOpen] = useState(false)
    const [subTitleEdit,setSubTitleEdit] = useState(false)

    const GetButtons = (element, index) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="edit">
                    <i className="bx bx-edit"></i>
                </a>
            </li> <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>)
    }
    const GetEditButton = (element, index) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="edit">
                    <i className="bx bx-edit"></i>
                </a>
            </li>
        </ul>)
    }
    const { Course, ProductList,Tutorial,SubscriptionFeature,SubscriptionTitle } = useSelector(State => State.Auth)
    const one = useSelector(State => State.Auth)
    // console.log("feature title",one);
    const [LIST, setLIST] = useState({})
    function breakString(inputString, spacesPerLine) {
        // Use regular expression to find every 10 spaces
        const regex = new RegExp(`(([^ ]* ){${spacesPerLine}}[^ ]*)`, 'g');

        // Replace matches with the matched substring followed by a newline
        const resultString = inputString.replace(regex, '$1<br>');

        return resultString;
    }
    const [openCateModal, setOpenCateModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteTutorialModal, setDeleteTutorialModal] = useState(false)
    const [tutorialProduct,setTutorialProduct] = useState([])
    const [filteredProducts,setFilteredProducts] = useState([])



    useEffect(() => {
        if (SubscriptionFeature.length) {
            console.log("SubscriptionFeature",SubscriptionFeature);
            let table_data = {
                data: [],
                columns: [
                    { title: 'Title' },
                    { title: 'Description' },
                    { title: 'Media' },
                    { title: 'Status' },
                    { title: 'Actions' }
                ],
            }
            SubscriptionFeature?.forEach(element => {
                const {
                    title,
                    media,
                    description,
                    status,
                    mediaType
                } = element
                table_data.data.push([
                    title || '- - -',
                    description || '- - -',
                    mediaType === "image" ? `<img src="${media}" width={200} alt class="product-C-img" />`: `<a href="${media}" target="_blank">View Video</a>`,
                    status === true ? "Enable" : "Disable" || "----",
                    GetButtons(element)
                ])
            });
            setLIST(table_data)
        }
    }, [SubscriptionFeature])

    // useEffect(()=>{
    //     if(Tutorial.length){
    //         Tutorial?.map((item)=> tutorialProduct?.push(item.products[0].product_id) )
    //     }
    // },[])


    const [createSubscription, setCreateSubscription] = useState({
        "title": "",
        "description": "",
        "media": null,
        "mediaType":null
    })
    const [editSubscriptionForm,setEditSubscriptionForm ] = useState({
        "title": "",
        "description": "",
        "media": null,
        "status": {},
        "mediaType":null
    })
    const [editSubscriptionTitleForm,setEditSubscriptionTitleForm ] = useState({
        "title": "",
        "description": "",
        "footer_description":"",
        "media": null,
        "mediaType":null
    })

    const [newCate, setNewCate] = useState([])
    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });

    const loadFile = (event, index, filed) => {
        var reader = new FileReader();
        reader.onload = async function () {
            let isMediaAdd = event.target.files[0]
            // ShowCurrentImage.src = reader.result;
            let file = {
                uri: reader.result,
                name: isMediaAdd.name,
                type: isMediaAdd.type, // it may be necessary in Android.
            }

            if (!filed) {
                let VIDEO = createSubscription?.media
                let DD = await getVideoDuration(isMediaAdd)
                VIDEO[index] = { ...VIDEO[index], url: isMediaAdd, file, duration: DD }
                setCreateSubscription((item) => ({ ...item, videos: VIDEO }))
            } else {
                setCreateSubscription((item) => ({ ...item, [filed]: isMediaAdd }))
            }
            // console.log(reader.result)
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
           setCreateSubscription((prevFormData) => ({
              ...prevFormData,
              "media": file,
              "mediaType": file?.type?.split("/")[0]
            }));
      };
    const EditHandleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
            setEditSubscriptionForm((prevFormData) => ({
              ...prevFormData,
              "media": file,
              "mediaType": file?.type?.split("/")[0]
            }));
      
    };
    const EditSubscriptionTitleHandle = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        setEditSubscriptionTitleForm((prevFormData) => ({
              ...prevFormData,
              "media": file,
              "mediaType": file?.type?.split("/")[0]
            }));
      
    };

    const handleVideoThumbnail = (event, index) => {
        var reader = new FileReader();
        reader.onload = async function () {
            let isMediaAdd = event.target.files[0]
            let file = {
                uri: reader.result,
                name: isMediaAdd.name,
                type: isMediaAdd.type, // it may be necessary in Android.
            }

            let VIDEO = createSubscription?.videos
            VIDEO[index] = { ...VIDEO[index], thumbnail: isMediaAdd }
            setCreateSubscription((item) => ({ ...item, videos: VIDEO }))
        };
        reader.readAsDataURL(event.target.files[0]);
    };


    const CategoriesImahe = (event, index, filed) => {
        var reader = new FileReader();
        reader.onload = async function () {
            let isMediaAdd = event.target.files[0]
            // ShowCurrentImage.src = reader.result;
            let file = {
                uri: reader.result,
                name: isMediaAdd.name,
                type: isMediaAdd.type, // it may be necessary in Android.
            }
            let VIDEO = [...newCate]
            VIDEO[index] = { ...VIDEO[index], file: isMediaAdd }
            setNewCate(VIDEO)
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(false)
    const handleSend = async() => {
        if(!createSubscription.media) return MessageShow("error", "Media is required");
        let valid = Validator(createSubscription)
        if (valid) {
            const formDataToSend = new FormData();
            formDataToSend.append('title', createSubscription.title);
            formDataToSend.append('media', createSubscription.media);
            formDataToSend.append('description', createSubscription.description);
            formDataToSend.append('mediaType', createSubscription.mediaType);
            dispatch(AddSubscription({ body: formDataToSend, callback: () => setLoading(false) }))
            setOpen(false)
            setCreateSubscription({
                "title": "",
                "description": "",
                "media": null,
                "mediaType":null
            })
            setLoading(true)
        }
    }

    const handleEid = () => {
        console.log("editSubscriptionForm",editSubscriptionForm);
        debugger
            if(!editSubscriptionForm.title) return MessageShow("error", "Title is required");
            if(!editSubscriptionForm.description) return MessageShow("error", "Description is required");
            const formDataToSend = new FormData();
            formDataToSend.append('title', editSubscriptionForm.title);
            formDataToSend.append('description', editSubscriptionForm.description);
            formDataToSend.append('media', editSubscriptionForm?.media?.name ? editSubscriptionForm?.media : null);
            formDataToSend.append('mediaType', editSubscriptionForm?.mediaType);
            formDataToSend.append('status', editSubscriptionForm?.status?.value);
            dispatch(EditSubscription({id:editSubscriptionForm?._id, body: formDataToSend, callback: () => setLoading(false) }))
            setEditOpen(false)
            setLoading(true)
    }

    const editSubscriptionTitle = () => {
        if(!editSubscriptionTitleForm.title) return MessageShow("error", "Title is required");
        if(!editSubscriptionTitleForm.description) return MessageShow("error", "Description is required");
        const formDataToSend = new FormData();
        formDataToSend.append('title', editSubscriptionTitleForm.title);
        formDataToSend.append('description', editSubscriptionTitleForm.description);
        formDataToSend.append('footer_description', editSubscriptionTitleForm.footer_description);
        formDataToSend.append('media', editSubscriptionTitleForm?.media?.name ? editSubscriptionTitleForm?.media : null);
        formDataToSend.append('mediaType', editSubscriptionTitleForm?.mediaType);

        dispatch(EditSubscriptionTitle({id:editSubscriptionTitleForm?._id, body: formDataToSend, callback: () => setLoading(false) }))
        setSubTitleEdit(false)
        setLoading(true)
}

    const handleSendCate = () => {
        let valid = Validator(newCate)
        if (valid) {
            let formdata = new FormData()
            let allTure = []
            newCate.forEach((items, index) => {
                if (items?.title) {
                    allTure.push(true)
                    formdata.append(`title`, items?.title)
                } else {
                    MessageShow('error', `Category ${index + 1} title is required`)
                    allTure.push(false)
                }
                if (items?.file) {
                    allTure.push(true)
                    formdata.append(`icon_${index}`, items?.file)
                } else {
                    MessageShow('error', `Category ${index + 1} icon is required`)
                    allTure.push(false)
                }
            })
            if (allTure?.filter(i => i)?.length) {
                dispatch(AddCategry({ body: formdata, callback: () => { setNewCate([]) } }))
            }
        }
    }

    const GetDeletButtons = (element) => {
        return ReactDomServer.renderToString(<ul className="tbl_actions_wrap d-flex align-items-center justify-content-start">
            <li>
                <a href="javaScript:;" data-data={JSON.stringify(element)} data-name="delete-Cate" >
                    <i className="bx bx-trash"></i>
                </a>
            </li>
        </ul>)
    }
    const [options, setOptions] = useState([{label:"Enable",value:true},{label:"Disable",value:false}])
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Subscription Features</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Subscription Features</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">

                        <div className="btn-group">
                            {Loading ? <Button text="Creating..." className="d-flex align-items-center btn btn-white font-18" icon={<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} />
                                : <Button text="Add Subscription" onClick={() => {
                                    setOpen(true)
                                }} className="d-flex align-items-center btn btn-primary font-18" icon={<i className="bx bx-plus ms-1" />} />}
                        </div>

                    </div>
                </div>
                <hr />

                <div className="card">
                    <div className="card-body">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                            <div className="ms-auto">
                                <div className="btn-group mr-3">
                                    <Button text="Feature Title" onClick={() => {
                                        setOpenCateModal(true)
                                    }} className="d-flex align-items-center btn btn-primary font-18 ml-3" />
                                </div>
                            </div>
                        </div>
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data ? <div className="table-responsive">
                                    <Table {...LIST} callback={(e, type) => {
                                        // console.log(e)
                                        if (type == 'edit') {
                                            setEditOpen(true)
                                            console.log("edit log",e);
                                            // setEditSubscriptionForm({...e,product:{label:e.product[0].title,value:{id:e.product[0].product_id,title:e.product[0].title}}})
                                            setEditSubscriptionForm({...e,status:{label:e.status == true ? "Enable" : "Disable",value:e.status }})
                                            // console.log("edit",editSubscriptionForm);
                                        } else {
                                            setDeleteTutorialModal(e)
                                        }
                                    }} />
                                    <Modal data={LIST.data} field={LIST.columns} SubmitAPI="/api-url" />
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Subscription Modal */}
                <ReactModal open={(open)} onClose={() =>{setOpen(false)}} title={'Add Subscription'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Title</label>
                            <input type={'text'} name={'title'} placeholder='Enter Title' onChange={(e) => setCreateSubscription((item) => ({ ...item, title: e.target.value }))} className="form-control" value={createSubscription?.title} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Description</label>
                            <textarea type={'text'} name={'description'} onChange={(e) => setCreateSubscription((item) => ({ ...item, description: e.target.value }))} className="form-control" value={createSubscription?.description} placeholder='Enter Description' />
                        </div>
                        <div className="col-md-12 mt-3">
                                <label htmlFor="input1" className="form-label">Media</label>
                                <input type={'file'} accept="video/*,image/*" onChange={handleFileChange} name={'x'} className="form-control" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleSend} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* Edit Subscription Modal */}
                <ReactModal open={(editOpen)} onClose={() =>{setEditOpen(false)}} title={'Edit Subscription'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Title</label>
                            <input type={'text'} name={'title'} onChange={(e) => setEditSubscriptionForm((item) => ({ ...item, title: e.target.value }))} className="form-control" placeholder='Enter Title' value={editSubscriptionForm?.title} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Description</label>
                            <textarea type={'text'} placeholder='Enter Description' name={'description'} onChange={(e) => setEditSubscriptionForm((item) => ({ ...item, description: e.target.value }))} className="form-control" value={editSubscriptionForm?.description} />
                        </div>
                        <div className="col-md-12 mt-3">
                                <label htmlFor="input1" className="form-label">Media</label>
                            {editSubscriptionForm?.mediaType == "video" ? <video src={editSubscriptionForm.media} width={"100%"} height={200} controls /> : <img style={{width:"100%",height:"200px"}} src={editSubscriptionForm?.media} />}
                                <input type={'file'} accept="video/*,image/*" onChange={EditHandleFileChange} name={'media'} className="form-control" />
                        </div>                        
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Status</label>
                            <Select  options={options}
                                value={editSubscriptionForm?.status}
                                onChange={(value) => {
                                    // console.log("value",value.value);
                                    setEditSubscriptionForm((item) => ({ ...item, status:value }))
                                    // console.log("change",editSubscriptionForm.product[0].title);
                                }}
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleEid} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* Delete Tutorial Modal */}
                <ReactModal open={deleteTutorialModal} title='Confirmation Alert!' onClose={() => setDeleteTutorialModal(false)} >
                    <div className="modal-body">
                        <div className="create_noti_form">
                            <h5>Do you want to delete {deleteTutorialModal?.title} subscription?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setDeleteTutorialModal(false)} data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-danger" onClick={() => { dispatch(DeleteSubscription(deleteTutorialModal?._id)); setDeleteTutorialModal(false) }} data-bs-dismiss="modal">Yes</button>
                    </div>
                </ReactModal>
                {/* Feature title */}
                <ReactModal open={openCateModal} onClose={() => setOpenCateModal(false)} title='Feature Title' >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <div className="tab-content py-3">
                                <div className="tab-pane fade show active" id="primaryhome2" role="tabpanel">
                                    <div className="table-responsive">
                                        <Table {...{
                                            data: SubscriptionTitle ? [
                                               [ `<img src="${SubscriptionTitle.media}"  alt="image" class="" />`,
                                                SubscriptionTitle?.title,
                                                SubscriptionTitle?.description,
                                                SubscriptionTitle?.footer_description || "---",
                                                GetEditButton(SubscriptionTitle)]
                                            ] :"",
                                            columns: [
                                                { title: 'Media' },
                                                { title: 'Title' },
                                                { title: 'Description' },
                                                { title: 'Footer' },
                                                { title: 'Action' },

                                            ], T_name: 'IID3'
                                        }}
                                            callback={(e) => {
                                                setSubTitleEdit(true)
                                                setOpenCateModal(false)
                                                setEditSubscriptionTitleForm(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={handleSendCate} text="Save Changes" />
                    </div>
                </ReactModal>
                {/* subscription Title Edit */}
                <ReactModal open={subTitleEdit} onClose={() =>{setSubTitleEdit(false)}} title={'Edit Subscription Title'}  >
                    <div class="modal-body">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Title</label>
                            <input type={'text'} name={'title'} onChange={(e) => setEditSubscriptionTitleForm((item) => ({ ...item, title: e.target.value }))} className="form-control" placeholder='Enter Title' value={editSubscriptionTitleForm?.title} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Description</label>
                            <textarea type={'text'} placeholder='Enter Description' name={'description'} onChange={(e) => setEditSubscriptionTitleForm((item) => ({ ...item, description: e.target.value }))} className="form-control" value={editSubscriptionTitleForm?.description} />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label htmlFor="input1" className="form-label">Footer Description</label>
                            <textarea type={'text'} rows={2} placeholder='Enter Footer Description' name={'description'} onChange={(e) => setEditSubscriptionTitleForm((item) => ({ ...item, footer_description: e.target.value }))} className="form-control" value={editSubscriptionTitleForm?.footer_description} />
                        </div>
                        <div className="col-md-12 mt-3">
                                <label htmlFor="input1" className="form-label">Image</label>
                                {!editSubscriptionTitleForm?.media?.name ? <img style={{width:"100%",height:"200px"}} src={editSubscriptionTitleForm?.media} /> : null}
                                <input type={'file'} accept="image/*" onChange={EditSubscriptionTitleHandle} name={'media'} className="form-control" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button className="btn btn-primary" onClick={editSubscriptionTitle} text="Save Changes" />
                    </div>
                </ReactModal>

            </div>
        </div>
    )
}

export default SubscriptionFeature