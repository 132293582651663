export const API_URLS = {
    BASE: '',
    BaseURL: `https://admin.trigwellcosmetics.com:4879/api`,
    // BaseURL: `http://localhost:4000/api`,
    LOGIN: '/user/login',
    ALL_USER: '/admin/Users',
    GET_PRODUCTS: '/admin/products',
    REELS: '/user/reels',
    UPDATE_REELS: '/admin/update-reels-products',
    UPLOAD_COURSE: '/courses/add',
    EDIT_COURSE: '/courses/edit',
    // GET_COURSE: '/courses/all',
    GET_COURSE: '/courses/admin/all',

    DELETE_COURSE: '/courses/delete/',
    DELETE_COURSE_OVERVIEW: 'courses/overview/delete/',
    DELETE_COURSE_CATEGORIES:"courses/filter/delete/",
    DELETE_COURSE_PRODUCTS:"courses/product/delete/",
    DELETE_COURSE_LESSON : "courses/lesson/delete/",
    MEMBERSHIP: '/admin/membership',
    STATS: '/admin/stats',
    CATE: '/admin/add/category',
    CATE_DELETE: '/admin/category/',
    /////
    GET_STAFF: '/admin/getStaff',
    ADD_STAFF: '/admin/addstaff',
    UPDATE_STAFF: '/admin/adminUpdateStaff',
    UPDATE_PROFILE: '/admin/updateProfile',
    DELETE_STAFF: '/admin/deleteStaff/',
    UPDATE_PROFILE_IMAGE: '/admin/updateImage',
    CHANGE_PASSWORD: '/admin/changePassword',

    SENT_NOTIFICATIONS: '/admin/sentNotification',
    GET_NOTIFICATIONS: '/admin/notifications',
    DELETE_NOTIFICATIONS: '/admin/deleteNotification/',

    // Video Tutorial
    ADD_TUTORIAL: '/admin/add/tutorial',
    GET_TUTORIAL: '/admin/get/tutorial',
    DELETE_TUTORIAL: '/admin/delete/tutorial/',
    TUTORIAL_CATEGORIES : '/admin/categories',
    UPDATE_TUTORIAL : "/admin/update/tutorial/",

    // Subscription Features
    GET_SUBSCRIPTION_TITLE : "/admin/get/about-subscription-title",
    UPDATE_SUBSCRIPTION_TITLE: "/admin/update/about-subscription-title/",
    GET_SUBSCRIPTION_FEATURE: "/admin/get/about-subscription",
    ADD_SUBSCRIPTION_FEATURE: "/admin/add/about-subscription",
    UPDATE_SUBSCRIPTION_FEATURE: "/admin/update/about-subscription/",
    DELETE_SUBSCRIPTION_FEATURE: "/admin/delete/about-subscription/",

    // Subscriptions 
    DELETE_SUBSCRIPTION: "/admin/delete/membership/",

    // Monthly Limit Subscription
    GET_MONTHLY_LIMIT : "/admin/get/monthly-subscription-limit",
    ADD_MONTHLY_LIMIT : "/admin/add/monthly-subscription-limit",
    UPDATE_MONTHLY_LIMIT : "/admin/update/monthly-subscription-limit/",
    DELETE_MONTHLY_LIMIT : "/admin/delete/monthly-subscription-limit/"
}
