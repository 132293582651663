import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Scripts from './components/Scripts';
import Dashboard from './pages/Dashboard';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import NotFound from './pages/NotFound';
import Users_Managment from './pages/Users_Managment';
import Content_managment from './pages/Content_managment';
import Ecommerce_Management from './pages/Ecommerce_Management';
import Social_Media_Reels from './pages/Social_Media_Reels';
import Membership_Management from './pages/Membership_Management';
import Reviews_Management from './pages/Reviews_Management';
import Role_Management from './pages/Role_Management';
import Support_Ticket from './pages/Support_Ticket';
import Static_Content from './pages/Static_Content';
import Admin_Notifications from './pages/Admin_Notifications';
import Setting from './pages/Setting';
import User_Profile from './pages/User_Profile';
import Login_Page from './pages/Login_Page';
import Verify_Login from './pages/Verify_Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { GetCourse, GetMemebership, GetNotifications, GetProducts, GetReels, GetStaff, getStats, getUser,GetTutorial, GetSubscriptionTitle, GetSubscription, GetFeatureTitle, GetMonthlyLimit } from './reducer/Auth'
import { useEffect } from 'react';
import ProductTutorial from './pages/Product-Tutorial';
import SubscriptionFeature from './pages/subscription-features';
import MonthlySubscriptionLimit from './pages/Monthly-subscription-limit';


function App() {
    const { User } = useSelector((STATE) => STATE.Auth)


    const dispatch = useDispatch()
    const token = useSelector((State) => State.Auth?.token)


    useEffect(() => {
        if (token) {
            // stats
            dispatch(getStats())
            dispatch(getUser())
            dispatch(GetProducts())
            dispatch(GetReels())
            dispatch(GetCourse())
            dispatch(GetMemebership())
            dispatch(GetStaff())
            dispatch(GetNotifications())
            dispatch(GetTutorial())
            dispatch(GetSubscription())
            dispatch(GetSubscriptionTitle())
            dispatch(GetFeatureTitle())
            dispatch(GetMonthlyLimit())

        }
    }, [token])




    return (
        <>
            <BrowserRouter >
                {User?._id ? <Sidebar /> : false}
                {User?._id ? <Header /> : false}
                <Routes >
                    <Route path='*' element={<NotFound />} />
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/user-management' element={<Users_Managment />} />
                    <Route path='/content-management' element={<Content_managment />} />
                    <Route path='/e-commerce-management' element={<Ecommerce_Management />} />
                    <Route path='/social-media-reels' element={<Social_Media_Reels />} />
                    <Route path='/membership-management' element={<Membership_Management />} />
                    <Route path='/product-tutorial' element={<ProductTutorial />} />
                    <Route path='/subscription-features' element={<SubscriptionFeature />} />
                    <Route path='/monthly-subscription-limit' element={<MonthlySubscriptionLimit />} />
                    <Route path='/reviews-management' element={<Reviews_Management />} />
                    <Route path='/role-management' element={<Role_Management />} />
                    <Route path='/support-ticket' element={<Support_Ticket />} />
                    <Route path='/static-content' element={<Static_Content />} />
                    <Route path='/admin-notifications' element={<Admin_Notifications />} />
                    <Route path='/setting' element={<Setting />} />
                    <Route path='/user-profile' element={<User_Profile />} />
                    <Route path='/auth-cover-signin' element={<Login_Page />} />
                    <Route path='/auth-cover-signup' element={<Verify_Login />} />
                </Routes>
                {User?._id ? <Footer /> : false}
                <Scripts />
                <ToastContainer />
            </BrowserRouter>

        </>
    );
}

export default App;