import React, { useEffect, useState } from 'react'
import Chart_Canvas from '../components/Chart_Canvas'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Table from '../components/Table'
const Dashboard = () => {

    let newArray = new Array(12).fill(0)
    let ChartData = {
        CourseInMonth: {
            type: "bar",
            chartID: 1,
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: '',
                    data: [...newArray],
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: "#f7971e",
                    borderRadius: 20,
                    borderWidth: 0
                },]
            },
            options: {
                maintainAspectRatio: false,
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        },
        ProductsList: {
            type: "bar",
            chartID: 2,
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: '',
                    data: [...newArray],
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: "#96c93d",
                    borderRadius: 20,
                    borderWidth: 0
                },]
            },
            options: {
                maintainAspectRatio: false,
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        },
        UserInMonth: {
            type: "bar",
            chartID: 3,
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: '',
                    data: [...newArray],
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: "#8e2de2",

                    borderRadius: 20,
                    borderWidth: 0
                },]
            },
            options: {
                maintainAspectRatio: false,
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        },
    }
    const { DashboardData } = useSelector((State) => State.Auth)
    useEffect(() => {
        if (DashboardData?.CourseInMonth) {
            DashboardData.CourseInMonth.forEach(element => {
                ChartData.CourseInMonth.data.datasets[0].data[element?._id.month - 1] = element?.InMonth.length
            });
            DashboardData.UserInMonth.forEach(element => {
                ChartData.UserInMonth.data.datasets[0].data[element?._id.month - 1] = element?.InMonth.length
            });
            DashboardData.ProductsList.forEach(element => {
                ChartData.ProductsList.data.datasets[0].data[element?._id.month - 1] = element?.InMonth.length
            });
        }

    }, [DashboardData])

    const [LIST, setLIST] = useState({})

    const { UserList } = useSelector((State) => State.Auth)

    useEffect(() => {
        if (UserList) {

            let table_data = {
                data: [],
                columns: [
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Email' },
                    { title: 'Phone No' },
                    { title: 'Shopify ID' },
                    { title: 'Points' },
                ],
            }
            UserList?.forEach(element => {
                const {
                    email,
                    firstName,
                    id,
                    lastName,
                    points,
                    phone,
                    createdAt
                } = element
                let day = createdAt?.split('T')[0]
                if (moment().isSame('day', 'day'))
                    table_data.data.push([
                        firstName || '- - -',
                        lastName || '- - -',
                        email || '- - -',
                        phone || '- - -',
                        id || '- - -',
                        points || '- - -',
                    ])
            });
            setLIST(table_data)

        }
    }, [UserList])



    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="dashTabs">
                    <ul className="nav nav-tabs d-flex align-items-center" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                <div className="col">
                                    <div className="card radius-10 bg-gradient-cosmic">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center card_box">
                                                <div className="me-auto">
                                                    <p className="mb-0 text-white">Total Users</p>
                                                    <h4 className="my-1 text-white">{DashboardData?.totalUser || 0}</h4>
                                                    <p className="mb-0 font-13 text-white">Total Registered Users</p>
                                                </div>
                                                <div>
                                                    <img src="assets/images/main-page/total-mama.png" alt="Users" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                <div className="col">
                                    <div className="card radius-10 bg-gradient-ibiza">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center card_box">
                                                <div className="me-auto">
                                                    <p className="mb-0 text-white">Total Courses</p>
                                                    <h4 className="my-1 text-white">{DashboardData?.totalCourse || 0}</h4>
                                                    <p className="mb-0 font-13 text-white">Total Courses Uploaded </p>
                                                </div>
                                                <div>
                                                    <img src="assets/images/main-page/team.png" alt="convos" />
                                                </div>
                                                {/* <div id="chart2"></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                <div className="col">
                                    <div className="card radius-10 bg-gradient-ohhappiness">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center card_box">
                                                <div className="me-auto">
                                                    <p className="mb-0 text-white">Total Products</p>
                                                    <h4 className="my-1 text-white">{DashboardData?.totalProducts || 0}</h4>
                                                    <p className="mb-0 font-13 text-white">Total Products on Store</p>
                                                </div>
                                                <div>
                                                    <img src="assets/images/main-page/product-icon.png" alt="events" />
                                                </div>
                                                {/* <div id="chart3"></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="support-tab" data-bs-toggle="tab" data-bs-target="#support" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                <div className="col">
                                    <div className="card radius-10 bg-gradient-kyoto">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center card_box">
                                                <div className="me-auto">
                                                    <p className="mb-0 text-white">Total Revenue</p>
                                                    <h4 className="my-1 text-white">£{(DashboardData?.totalRevenu || 0) / 100}</h4>
                                                    <p className="mb-0 font-13 text-white">Total Revenue Generated</p>
                                                </div>
                                                <div>
                                                    <img src="assets/images/main-page/suitcase.png" alt="tickets" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="card radius-10">
                                    <div className="card-body">
                                        <div className="chart-container-9">
                                            {DashboardData?.totalUser ? < Chart_Canvas chart_data={ChartData.UserInMonth} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="card radius-10">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="chart-container-9">
                                            {DashboardData?.totalCourse ? < Chart_Canvas chart_data={ChartData.CourseInMonth} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <div className="card radius-10">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="chart-container-9">
                                            {DashboardData?.totalProducts ? < Chart_Canvas chart_data={ChartData.ProductsList} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane" id="support" role="tabpanel" aria-labelledby="support-tab">
                                <div className="card radius-10">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="chart-container-9">
                                            <Chart_Canvas chart_data={chart_4} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="support1" role="tabpanel" aria-labelledby="support-tab1">
                                <div className="card radius-10">
                                    <div className="card-body">
                                        <div className="chart-container-9">
                                            <Chart_Canvas chart_data={chart_5} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="card-header mt-3">
                    <div className="d-flex align-items-center">
                        <div>
                            <h6 className="mb-2">New Users</h6>
                        </div>
                    </div>
                </div>
                <div className="card radius-10">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-primary" role="tablist">
                        </ul>
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                {LIST?.data?.length ? <div className="table-responsive">
                                    <Table {...LIST} />
                                    {/* <Modal data={LIST.data}  /> */}
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Dashboard