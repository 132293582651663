import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './reducer/store';
import reportWebVitals from './reportWebVitals';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
const root = ReactDOM.createRoot(document.getElementById('root'));

const persistor = persistStore(store);

root.render(
  // <AppProvider>
  //   <App />
  // </AppProvider>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// https://bellamie.biz/admin/api/get-categories
// https://bellamie.biz/admin/api/get-product/1
// https://bellamie.biz/admin/api/get-colors
// https://bellamie.biz/admin/api/get-sizes
// https://bellamie.biz/admin/api/get-latest-products
// https://bellamie.biz/admin//
// Admin Credentials :
// admin@bellamie.com
// qwerty123

// Products API:
// https://bellamie.biz/admin/api/get-products

// Product Detail API:
// https://bellamie.biz/admin/api/get-product/{id}

// User Login API (POST):
// https://bellamie.biz/admin/api/user-login
// https://bellamie.biz/admin/api/save-order