import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="overlay toggle-icon" />
            <a href="javaScript:;" className="back-to-top"><i className="bx bxs-up-arrow-alt" /></a>
            <footer className="page-footer">
                <p className="mb-0">Copyright © 2024. All right reserved.</p>
            </footer>

        </>
    )
}

export default Footer
