import React from 'react'
import Table from '../components/Table'
import Modal_Actions from '../components/Modal_Actions'
import ReactDomServer from 'react-dom/server';
import Modal from '../components/Modal';

const Support_Ticket = () => {
    const GetButtons = (id) => {
        return ReactDomServer.renderToString(<Modal_Actions view delete id={id} />)
    }
    let table_data = {
        data: [
            ['<img src="assets/images/main-page/profile.jpg" class="product-img-2" alt="product img">', "Natalia", 'Michel', 'Michel', '(123)-456-7890', '(123)-456-7890', GetButtons(0)],
            ['<img src="assets/images/main-page/profile.jpg" class="product-img-2" alt="product img">', "Natalia", 'Michel', 'Michel', '(123)-456-7890', '(123)-456-7890', GetButtons(1)],
            ['<img src="assets/images/main-page/profile.jpg" class="product-img-2" alt="product img">', "Natalia", 'Michel', 'Michel', '(123)-456-7890', '(123)-456-7890', GetButtons(2)],
            ['<img src="assets/images/main-page/profile.jpg" class="product-img-2" alt="product img">', "Natalia", 'Michel', 'Michel', '(123)-456-7890', '(123)-456-7890', GetButtons(3)],
            ['<img src="assets/images/main-page/profile.jpg" class="product-img-2" alt="product img">', "Natalia", 'Michel', 'Michel', '(123)-456-7890', '(123)-456-7890', GetButtons(4)],
        ],
        columns: [
            { title: 'Profile Picture' },
            { title: 'First Name' },
            { title: 'Last Name' },
            { title: 'Email' },
            { title: 'Phone No' },
            { title: 'Emergency Contact' },
            { title: 'Actions' }
        ],
    }
    const modal_fields = [
        {
            type: 'file',
            name: 'Profile Picture',
            label: 'Profile Picture',
        },
        {
            type: 'text',
            name: 'First Name',
            label: 'First Name',
        },
        {
            type: 'text',
            name: 'Last Name',
            label: 'Last Name',
        },
        {
            type: 'email',
            name: 'Email',
            label: 'Email',
        },
        {
            type: 'phone',
            name: 'Phone No',
            label: 'Phone No',
        },
        {
            type: 'phone',
            name: 'Emergency Contact',
            label: 'Emergency Contact',
        }
    ]
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Support Tickets</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt" /></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Support Tickets</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-primary" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" data-bs-toggle="tab" href="#primaryhome" role="tab" aria-selected="true">
                                    <div className="d-flex align-items-center">
                                        <div className="tab-icon"><i className="bx bx-check-circle font-18 me-1" />
                                        </div>
                                        <div className="tab-title">Resolved</div>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" data-bs-toggle="tab" href="#primaryprofile" role="tab" aria-selected="false">
                                    <div className="d-flex align-items-center">
                                        <div className="tab-icon"><i className="bx bx-time font-18 me-1" />
                                        </div>
                                        <div className="tab-title">Pending</div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content py-3">
                            <div className="tab-pane fade show active" id="primaryhome" role="tabpanel">
                                <div className="table-responsive">
                                    <Table {...table_data} />
                                    <Modal data={table_data.data} field={modal_fields} SubmitAPI="/api-url" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Support_Ticket