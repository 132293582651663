import axios from "axios";
import { API_URLS } from "./EndPoints";
import { getAccessToken } from "../reducer/Auth";
import { store } from "../reducer/store";

const instance = axios.create({
    baseURL: API_URLS.BaseURL,
    headers: {
        //  Authorization: token,
        'Content-Type': "application/json",
        timeout: 1000,
    },
    // .. other options
});


export const POST = async (url, data) => {
    const onSuccess = ({ data }) => {
        return data;
    };
    const onFailure = (error) => {
        throw error;
    };
    try {
        const result = await instance({
            url,
            data,
            method: "POST",
        })
        return onSuccess(result);
    } catch (error) {
        return onFailure(error);
    }
}
export const GET = async (url, data) => {
    const token = getAccessToken(store.getState());
    const onSuccess = ({ data }) => {
        return data;
    };
    const onFailure = (error) => {
        throw error;
    };
    try {
        const result = await instance({
            url,
            data,
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
                'Content-Type': "application/json",
                timeout: 1000,
            }
        })
        return onSuccess(result);
    } catch (error) {
        return onFailure(error);
    }
}

export const AuthPOST = async (url, data, isFORMDATA = false) => {
    const token = getAccessToken(store.getState());
    const onSuccess = ({ data }) => {
        return data;
    };
    const onFailure = (error) => {
        throw error;
    };
    try {
        const result = await instance({
            url,
            data,
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                'Content-Type': isFORMDATA ? 'multipart/form-data' : "application/json",
                timeout: 1000,
            }
        })
        return onSuccess(result);
    } catch (error) {
        return onFailure(error);
    }
}

export default instance;