import React from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';

const AddCate = ({ craeteNew, setCreateNew, index, item, handleIcon }) => {
  

    return (
        <div class="card mt-3">
            <div class="col mt-3 row">
                <div class="col-md-12">
                    <div>
                        <div class="card-body">

                            <h5 class="card-title">{index + 1}.Category</h5>
                            <form>
                                <div className="col-md-12">
                                    <label htmlFor="input1" className="form-label">Title</label>
                                    <input type={'text'} name={'over_view'} className="form-control" onChange={(e) => {
                                        let VIDEO = [...craeteNew]
                                        VIDEO[index] = { ...VIDEO[index], title: e.target.value }
                                        setCreateNew(VIDEO)
                                    }} />
                                </div>
                                <div className="col-md-12 mt-3 ">
                                    <div class="fileUpload mt-3">
                                        <input type="file" accept="image/*" class="upload" onChange={(e) => handleIcon(e, index)} />
                                        {item?.file ? <span>{item?.file?.name}</span> : <span>Upload a Icon</span>}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCate






