import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

const ReactModal = ({ open, onClose, children, title = 'Edit' }) => {
    // console.log(open)
    return (
        // <Modal open={open} onClose={onClose} >
        //     {children}
        // </Modal>
        open ?
            <div className="modal fade show" id="edit_event_modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{textTransform:"none "}} id="exampleModalLabel">{title}</h5>
                            {onClose ?<button type="button" className="btn-close" onClick={onClose} data-bs-dismiss="modal" aria-label="Close" /> :""}
                            
                        </div>
                        <div className="modal-body">
                            <div className="profile_modal_wrap">
                                <div className="main-body">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
    );
}

export default ReactModal